import Modal from 'react-modal';
import translate from '../../Translations/index';
import close from '../../../Public/Images/Icons/close.webp';
import { UseAddUrl} from '../Hooks/Affiliate';
import { withApollo } from 'react-apollo';
import Loading from '../../../GlobalComponents/Loading';

const AddAffiliateUrl = ({affiliateID,SetAffiliateID,customer,client}) => {

    const [formData,loading,SetUrl,AddUrl] = UseAddUrl(client,SetAffiliateID,affiliateID);

    return(
        <Modal
            className="Modal__Bootstrap modal-lg modal-dialog"
            closeTimeoutMS={150}
            isOpen={(affiliateID !== 0 ? true : false)}
            onRequestClose={() => SetAffiliateID(0)}
        >
            <div className="modal-content">
                <div className="modal-header">
                    {translate.t("AddAffiliateUrl")} - {(affiliateID == 1 ? translate.t("WantToEarn") : translate.t("WantToHelp"))}
                    <img src={close} className="close" onClick={() => SetAffiliateID(0)} />
                </div>
                <div className="modal-body">

                    {loading ?

                        <Loading />
                    :
                        <>
                            <p className="f-14">Odkaz se skládá ze dvou části. První je pevná neměnná část, a to <strong>https://www.{customer.domain.domainName}/a/</strong></p>
                            <p className="f-14">Druhou část odkazu (za posledním lomítkem) si můžeš zvolit podle sebe, například ve tvaru: <strong>me-jmeno</strong>. <br /> Výsledný odkaz bude ve tvaru <strong>https://www.{customer.domain.domainName}/a/me-jmeno</strong></p>

                            <hr />

                            <div className="form-group">
                                <label>Zvol druhou část odkazu</label>
                                <div>
                                    <input onChange={(e) => SetUrl(e.target.value)} value={formData.originalUrl} className="form-control" type="text" />
                                </div>
                            </div>

                            <div>
                                <label>Tvůj výsledný odkaz bude</label>
                                <div className="alert alert-info no-margin">https://www.{customer.domain.domainName}/a/{formData.url}</div>
                            </div>
                        </>

                    }
                                                
                </div>

                {!loading ?
                    <div className="modal-footer"> 
                        <button type="button" className="btn btn-primary flex-fill" onClick={() => AddUrl()}>{translate.t("Add")}</button>
                        <button type="button" className="btn btn-danger" onClick={() => SetAffiliateID(0)}>{translate.t("Close")}</button>
                    </div>
                :null}

            </div>
        </Modal>
    )
}

export default withApollo(AddAffiliateUrl);