import { useState } from 'react';
import options from '../../../Public/Images/Icons/options.webp';
import verified from '../../../Public/Images/Icons/verified.webp';
import { CONFIRM_GUIDE_RESERVATION,MOVE_GUIDE_RESERVATION } from '../../Reservations/Queries/guideReservation';
import {useMutation} from 'react-apollo';
import translate from '../../Translations/index';
import { UseSocket } from '../../Socket/Components/Socket';
import moment from 'moment';

const RoomReservationListItem = ({
    item,
    isModerator,
    setSelectedCustomer,
    resBlocks,
    roomNumber,
    OnRemoveUser
}) => {

    const {ConfirmGuideReservation,MoveGuideReservation} = UseSocket();

    const [optionsPanelPos, SetOptionsPanelPos] = useState("");
    const [showOptions, SetShowOptions] = useState(false);
    const [moveTo, SetMoveTo] = useState("1-"+(resBlocks ? resBlocks[0].timeFromStr : ""));

    const [MovGuideReservation] = useMutation(MOVE_GUIDE_RESERVATION,{
        onCompleted:(resData) => {

            var moveToData = moveTo.split("-");
                
            MoveGuideReservation({
                roomNumber,
                resData:{
                    guideReservationID:item.guideReservationID,
                    guideReservationTypeID:moveToData[0],
                    resDate:moment().format("YYYY-MM-DD") + " " + moveToData[1]
                }
            })

            SetShowOptions(false);

        },
        onError:(err) => {
            console.log(err);
        }
    });

    const [ConfGuideReservation] = useMutation(CONFIRM_GUIDE_RESERVATION,{
        onCompleted:(resData) => {
                        
            ConfirmGuideReservation({
                roomNumber,
                resData:{
                    confirmed: (item.confirmed == 0 ? 1 : 0),
                    guideReservationID:item.guideReservationID
                }
            })
            SetShowOptions(false);

        },
        onError:(err) => {
            console.log(err);
        }
    });

    const ConfirmRes = () => {
        ConfGuideReservation({
            variables:{
                confirmed: (item.confirmed == 0 ? 1 : 0),
                guideReservationID:item.guideReservationID
            }
        })
    }

    const MoveRes = () => {

        var moveToData = moveTo.split("-");

        MovGuideReservation({
            variables:{
                guideReservationID:item.guideReservationID,
                guideReservationTypeID:moveToData[0],
                resDate:moment().format("YYYY-MM-DD") + " " + moveToData[1]
            }
        })
    }

    const ShowOptions = (e) => {

        e.persist();
        var windowHeight = window.innerHeight;
        var clientY = e.clientY;

        if(windowHeight - clientY < 345)
            SetOptionsPanelPos("top");
        else
            SetOptionsPanelPos("");

        SetShowOptions(true);
    }

    const RemoveFromRoom = (customerID) => {

        OnRemoveUser(customerID);
        SetShowOptions(false);
    }

    return(
        <>
            {isModerator ?
                <>
                    
                    <div className = {"status" + (item.isOnline ? " online" : "")}></div>
                    
                    <a href="" onClick={(e) => {e.preventDefault(); setSelectedCustomer(item.customerID)}}>
                        {(item.customer.astralName != "" ? 
                            item.customer.astralName : 
                            (item.customer.roomName != "" ? 
                                item.customer.roomName : item.customer.name + " " + item.customer.surname
                            )
                        )}
                    </a>

                    {item.confirmed == 1 ?
                        <img 
                            title = {translate.t("AlreadySpoke")}
                            className="icon" 
                            src = {verified} 
                        />
                    :null}

                    <img 
                        onClick = {(e) => ShowOptions(e)} 
                        className="icon options" 
                        src = {options} 
                    />

                    {showOptions ?
                        <>
                            <div className={"options-panel " + optionsPanelPos}>
                                {resBlocks ?
                                    <>
                                        <div className="form-group">
                                            <button 
                                                onClick={() => ConfirmRes()}
                                                className="btn btn-primary btn-block"
                                            >
                                                {item.confirmed == 0 ?
                                                    (translate.t("ConfirmSpeaking"))
                                                :
                                                    (translate.t("ConfirmNotSpeaking"))
                                                }
                                            </button>
                                        </div>
                                        <div className="form-group">
                                            <label>{translate.t("MoveReservationTo")}</label>
                                            <div className="row">
                                                <div className="col-6">
                                                    <select 
                                                        value = {moveTo}
                                                        onChange = {(e) => SetMoveTo(e.target.value)}
                                                        name = "" 
                                                        className = "form-control"
                                                    >
                                                        {resBlocks.map((item,index) => (
                                                            <option 
                                                                key = {index} 
                                                                value = {"1-" + item.timeFromStr}
                                                            >{item.timeFromStr}</option>
                                                        ))}
                                                        <option value = {"2-00:00:00"}>{translate.t("Anytime")}</option>
                                                        <option value = {"3-00:00:00"}>{translate.t("JustListening")}</option>
                                                        <option value = {"4-00:00:00"}>{translate.t("IndividualGuidance")}</option>
                                                        
                                                    </select>
                                                </div>
                                                <div className="col-6">
                                                    <button 
                                                        onClick = {() => MoveRes()}
                                                        className="btn btn-primary btn-block"
                                                    >{translate.t("ToMove")}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                :null}
                                <div>
                                    <button 
                                        onClick = {() => RemoveFromRoom(item.customerID)}
                                        className="btn btn-danger btn-block"
                                    >{translate.t("RemoveFromRoom")}</button>
                                </div>
                            </div>
                            <div 
                                onClick = {() => SetShowOptions(false)}
                                className="options-panel-overlay"
                            ></div>
                        </>
                    :null}
                </>
            :
                <>                                
                    <div className="name">
                        {(item.customer.astralName != "" ? 
                            item.customer.astralName : 
                            (item.customer.roomName != "" ? 
                                item.customer.roomName : item.customer.name + " " + item.customer.surname
                            )
                        )}
                    </div>
                    {item.confirmed == 1 ?
                        <img 
                            title = {translate.t("AlreadySpoke")}
                            className="icon" 
                            src = {verified} 
                        />
                    :null}
                </>
            }
        </>
    )

}

export default RoomReservationListItem;