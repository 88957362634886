import React,{useEffect, useState,useRef} from 'react';
import {useQuery,useSubscription,useMutation,withApollo} from 'react-apollo';
import {GET_WELCOME_PAGE_DATA,GET_CUSTOMER_DETAILS,UPDATE_CUSTOMER_WELCOME_PAGE_STEP} from '../Queries/customer';
import Loading from '../../../GlobalComponents/Loading';
import translate from '../../Translations/index';
import {UPDATE_PAYU_PAYMENT_SUBSCRIPTION} from '../../PayU/Queries/payu';
import moment from 'moment';
import ReNewButton from '../../PayU/Components/ReNewButton';
import Modal from 'react-modal';
import Notification from '../../../Library/notification';

const WelcomePage = (props) => {

    props.history.push("daily");

    var modal;

    const [video, setVideo] = useState(null);
    const [step, setStep] = useState(1);
    const [greyButton, SetGreyButton] = useState([true,false,false]);
    const [showWelcomeModal, setShowWelcomeModal] = useState(true);

    const {data:welcomeData,loading:welcomeLoading,error:welcomeError} = useQuery(GET_WELCOME_PAGE_DATA,{
        fetchPolicy: 'network-only',
        variables:{
            lang: translate.locale
        }
    });

    const {data,loading,error,refetch:affDataRefetch} = useQuery(GET_CUSTOMER_DETAILS,{
        fetchPolicy: 'network-only',
    });

    const { data: subscibeData, loading:subscibeLoading } = useSubscription(UPDATE_PAYU_PAYMENT_SUBSCRIPTION,{
        onSubscriptionData(d){

            if(d.subscriptionData.data.updatePayUPayment && data && data.customerContext && d.subscriptionData.data.updatePayUPayment.customerID == data.customerContext.customerID){
                affDataRefetch();
            }
        }
    });

    const [updateCustomerWelcomePageStep,{error:addError,loading:AddLoading}] = useMutation(UPDATE_CUSTOMER_WELCOME_PAGE_STEP,{
        onCompleted: (data) => {

            /*
            var notify = new Notification();
            notify.setNotification(null,translate.t("SuccessfullySaved"),true,false,props.client);
            */
        }

    });

    useEffect(() => {

        if(video && step == 1){

            var player = new window.Vimeo.Player(video);
            player.on('timeupdate', getVideoDuration);

            return () => {
                player.off('timeupdate', getVideoDuration);
            }
        }

    },[step,video])

    useEffect(() => {

        if(data && data.customerContext){

            if(data.customerContext.welcomePageStep > 1){
                var arr = [];
                for(let i = 0; i < 3; i++){
                    if(i <= data.customerContext.welcomePageStep - 1)arr[i] = false;
                    else arr[i] = true;
                }
                SetGreyButton(arr);
            }

            setStep(data.customerContext.welcomePageStep);
        }

    },[data])


    const getVideoDuration = (data) => {
        if(data.percent >= 0.5){
            var arr = [...greyButton];
            arr[step - 1] = false;
            if(greyButton[step - 1])SetGreyButton(arr);
        }
    }

    const nextStep = () => {

        if(!greyButton[step - 1]){
            if(step != 3){

                setStep(step + 1);
                modal.node.firstChild.scroll({top: 0, left: 0, behavior: 'smooth' }); 

                updateCustomerWelcomePageStep({
                    variables:{
                        customerID: data.customerContext.customerID,
                        step: (step + 1)
                    }
                })
            }
        }
    }

    const previousStep = () => {

        setStep(step - 1);
        modal.node.firstChild.scroll({top: 0, left: 0, behavior: 'smooth' }); 

        /*
        updateCustomerWelcomePageStep({
            variables:{
                customerID: data.customerContext.customerID,
                step: (step - 1)
            }
        })*/
   
    }

    var loadingData = false;
    if(welcomeLoading || loading)loadingData = true;

    if(data && data.customerContext.membershipTo != null && moment(data.customerContext.membershipTo) > moment()){
        props.history.push("daily");
    }

    return(
        <div id="room" className="whole-container d-flex justify-content-center align-items-center flex-column">

            {loadingData ?
                <Loading />
            :
                <>
                    <div>

                        {!loading && data && (data.customerContext.membershipTo == null || (moment(data.customerContext.membershipTo).hours(23).minutes(59).seconds(59) < moment() && data.customerContext.rules.showMembershipButton)) ?
                            <div id="no-membership" className="in-modal no-border-bottom">


                                {data.customerContext.lastCustomerPayment && data.customerContext.lastCustomerPayment.status == "PENDING" ?

                                    <div className="alert alert-warning text-center">{translate.t("PaymentIsPending")}</div>

                                :
                                    (data.customerContext.lastCustomerPayment && data.customerContext.lastCustomerPayment.status == "CANCELED" ?

                                        <>
                                            <div className="alert alert-danger text-center">{translate.t("PaymentIsCanceled")}</div>
                                            <p><ReNewButton firstPayment = {(data.customerContext.membershipTo != null ? false : true)} regCode={data.customerContext.regCode} text={translate.t("BecomeVS")} /></p>
                                        </>
                                        :
                                        <ReNewButton firstPayment = {(data.customerContext.membershipTo != null ? false : true)} regCode={data.customerContext.regCode} text={translate.t("BecomeVS")} />
                                    )
                                }			       

                            </div>
                        :null}

                    </div>
                    <div>
                        <button className="btn btn-transparent mt-3" onClick={() => setShowWelcomeModal(true)}>{translate.t("BackToVideos")}</button>
                    </div>
                        
                    <Modal
                        className="Modal__Bootstrap modal-dialog modal-xl"
                        closeTimeoutMS={150}
                        isOpen={showWelcomeModal}
                        onRequestClose={() => setShowWelcomeModal(false)}
                        ref={el => (modal = el)}
                    >
                        <div className="modal-content no-background">

                            <div className="modal-header d-flex align-items-center no-border-bottom white">
                                <button type="button" className="close ml-auto" onClick={() => setShowWelcomeModal(false)}>                                    
                                    <span aria-hidden="true">x</span>
                                    <span className="sr-only">Close</span>
                                </button>
                            </div>
                            
                            <div className="modal-body tiny-content white no-top-padding event border-bottom-radius">

                                <div dangerouslySetInnerHTML={{__html:welcomeData.welcomePage[step-1].text}}></div>
                                <iframe key={step} ref={el => setVideo(el)} src={welcomeData.welcomePage[step-1].videoUrl} width="425" height="350" allowFullScreen="allowFullScreen"></iframe>
                                {welcomeData.welcomePage[step-1].videoDesc && welcomeData.welcomePage[step-1].videoDesc != "" ?
                                    <div className="video-desc" dangerouslySetInnerHTML={{__html:welcomeData.welcomePage[step-1].videoDesc}}></div>
                                :null}
                                {welcomeData.welcomePage[step-1].text2 && welcomeData.welcomePage[step-1].text2 != "" ?
                                    <div dangerouslySetInnerHTML={{__html:welcomeData.welcomePage[step-1].text2}}></div>
                                :null}

                            </div>

                            <div className="text-center">

                                {step != 3 ?
                                    
                                    <button onClick={() => nextStep()} className={"btn btn-primary btn-large mt-3 " + (greyButton[step - 1] ? "btn-grey" : "")}>
                                        {welcomeData.welcomePage[step-1].buttonText}
                                        {greyButton[step - 1] ?
                                            <div className="info">{translate.t("continueAfter50")}</div>
                                        :null}
                                    </button>

                                :null}

                                {!loading && step == 3 && data && (data.customerContext.membershipTo == null || (moment(data.customerContext.membershipTo).hours(23).minutes(59).seconds(59) < moment() && data.customerContext.rules.showMembershipButton)) ?
                                    <div id="no-membership" className="in-modal no-border-bottom">


                                        {data.customerContext.lastCustomerPayment && data.customerContext.lastCustomerPayment.status == "PENDING" ?

                                            <div className="alert alert-warning text-center">{translate.t("PaymentIsPending")}</div>

                                        :
                                            (data.customerContext.lastCustomerPayment && data.customerContext.lastCustomerPayment.status == "CANCELED" ?

                                                <>
                                                    <div className="alert alert-danger text-center">{translate.t("PaymentIsCanceled")}</div>
                                                    <p><ReNewButton firstPayment = {(data.customerContext.membershipTo != null ? false : true)} regCode={data.customerContext.regCode} domain={data.customerContext.domain} text={welcomeData.welcomePage[step-1].buttonText} /></p>
                                                </>
                                                :
                                                <ReNewButton firstPayment = {(data.customerContext.membershipTo != null ? false : true)} regCode={data.customerContext.regCode} domain={data.customerContext.domain} text={welcomeData.welcomePage[step-1].buttonText} />
                                            )
                                        }			       
      
                                    </div>
                                :null}

                                {step != 1 ?
                                    <div className="mt-2">
                                        <button onClick={() => previousStep()} className="back-button">{translate.t("BackToPreviousVideo")}</button>
                                    </div>
                                :null}
                            </div>
                            
                        </div>
                    </Modal>

                </>
            
            }  

        </div>
    )

}

export default withApollo(WelcomePage);