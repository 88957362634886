import translate from '../../Translations/index';
import React,{ Component, useEffect, useState } from 'react';
import {useQuery } from 'react-apollo';
import Questionnaire from './Questionnaire';
import withAuthentication from '../../Auth/Components/WithAuthentication';
import {GET_CUSTOMER_DETAILS} from '../../Customer/Queries/customer';
import Loading from '../../../GlobalComponents/Loading';
import logo from '../../../Public/Images/logo.svg';

const Questions = (props) => {

    const {data,loading,error} = useQuery(GET_CUSTOMER_DETAILS,{
        fetchPolicy: 'network-only',
    });

    const goToPortal = () => {
        props.history.push('/elite');
    }

    console.log(data);

    return(
        <div className="wholeContent" id={"l-" + translate.locale}>			       
 	      
 	        <div id="layout">

                <div id="questions">

                    <div className="text-center">
                        <img className="logo" alt={translate.t('AstralProjection')} src={logo} />
                    </div>

                    <div className="card">

                        <div className="card-header">{translate.t('AstralProjection')}</div>
                        <div className="card-body">

                            {loading ?
                                <Loading />
                            :
                                <Questionnaire 
                               
                                    successMessage = {
                                        (data.customerContext.courseID != 0 
                                            ? 
                                                data.customerContext.course.successMessage 
                                            : 
                                                null
                                        )
                                    }
                                    questionnaireID = {data.customerContext.questionnaireID} 
                                    customerID = {data.customerContext.customerID} 
                                    finished={goToPortal} 
                                />
                            }

                        </div>
                    </div>

                </div>


             </div>

        </div>
    )

}

export default withAuthentication(Questions);