import React,{ Component } from 'react';
import {useQuery,useSubscription} from 'react-apollo';
import { GET_CUSTOMER_DETAILS } from '../Modules/Customer/Queries/customer';
import {UPDATE_PAYU_PAYMENT_SUBSCRIPTION} from '../Modules/PayU/Queries/payu';
import ReNewButton from '../Modules/PayU/Components/ReNewButton';
import moment from 'moment';
import translate from '../Modules/Translations/index';
import { GET_SETTINGS } from '../GlobalQueries/globalQueries';
import DaysToSubscribeInfo from './DaysToSubscribeInfo';
import { useState } from 'react';

const NoMembership = () => {

    const [daysToSubscribe, SetDaysToSubscribe] = useState(0);
    const {data,loading,error,refetch:affDataRefetch} = useQuery(GET_CUSTOMER_DETAILS,{
        fetchPolicy: 'network-only',
    });

    const {data:settingsData,loading:settingsLoading} = useQuery(GET_SETTINGS,{
        fetchPolicy: 'network-only',
        variables:{
            lang:"cz"
        }
    });

    const { data: subscibeData, loading:subscibeLoading } = useSubscription(UPDATE_PAYU_PAYMENT_SUBSCRIPTION,{
        onSubscriptionData(d){

            if(d.subscriptionData.data.updatePayUPayment && data && data.customerContext && d.subscriptionData.data.updatePayUPayment.customerID == data.customerContext.customerID){
                affDataRefetch();
            }
        }
    });
  	
    return (
       <>
        {!loading && !settingsLoading && data && ((moment(data.customerContext.membershipTo).hours(23).minutes(59).seconds(59) < moment() && data.customerContext.rules.showMembershipButton)) ?
            <div id="no-membership">


                {data.customerContext.lastCustomerPayment && data.customerContext.lastCustomerPayment.status == "PENDING" ?

                    <div className="alert alert-warning text-center">{translate.t("PaymentIsPending")}</div>

                :
                    <>
                        {data.customerContext.lastCustomerPayment && data.customerContext.lastCustomerPayment.status == "CANCELED" ?
                            <div className="alert alert-danger text-center mb-2">{translate.t("PaymentIsCanceled")}</div>
                        :null}
                        
                        {settingsData.settings.closePortal == 0 || (settingsData.settings.closePortal == 1 && daysToSubscribe >= 0) ?
                            <ReNewButton regCode={data.customerContext.regCode} domain={data.customerContext.domain} text={translate.t("JoinUsToHaveFullAccess")} />
                        :null}

                        {settingsData.settings.closePortal == 1 ?
                            <DaysToSubscribeInfo
                                membershipTo = {data.customerContext.membershipTo}
                                OnSetDays = {(days) => SetDaysToSubscribe(days)}
                            />
                        :null}
                    </>
                }			       
         
            </div>
        :null}
        </>
   
    );
  
}

export default NoMembership;
