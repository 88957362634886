import React,{ Component } from 'react';
import { withApollo } from 'react-apollo';
import {Query} from 'react-apollo';
import {GET_NOTIFICATIONS} from '../GlobalQueries/globalQueries';


class Notification extends Component {
	
    render() {
			  
		return (
				
			<Query 
				query={GET_NOTIFICATIONS}
			>

				{
					({data,loading,error,fetchMore,client}) => {

						setTimeout(() => {
							
							const { notifications } = client.cache.readQuery({ query: GET_NOTIFICATIONS });
							if(notifications.length > 0)client.writeData({data:{notifications:notifications.slice(0,-1)}})
							
						},6000)

						return(
							<div id="notification">
								{data && data.notifications && data.notifications.map((item,index) => (
									<div className="notify" key={index}>
										{item.error &&
											<div className="alert alert-danger text-center">{item.text}</div>
										}
										{item.success &&
											<div className="alert alert-success text-center">{item.text}</div>
										}
										
									</div>
								))}
							</div>
						);

					}
				}

			</Query>
			
		);
    }
  
}


export default withApollo(Notification);
