import React,{ useState,useRef, useEffect } from 'react';
import translate from '../../Translations/index';
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';
import ContentEditable from 'react-contenteditable'
import emoji from '../../../Public/Images/Icons/emoji.webp';
import addImage from '../../../Public/Images/Icons/add_image.webp';
import send from '../../../Public/Images/Icons/send.webp';

const emojiPickerLangOptions = {
    search: 'Vyhledat',
    clear: 'Vymazat', // Accessible label on "clear" button
    notfound: 'Smajlík nenalezen',
    skintext: 'Vyberte si vlastní tón pokožky',
    categories: {
        search: 'Výsledky vyhledání',
        recent: 'Často používané',
        smileys: 'Smajlíci',
        people: 'Smajlíci',
        nature: 'Zvířata a příroda',
        foods: 'Jídlo a pití',
        activity: 'Aktivity',
        places: 'Cestování a místa',
        objects: 'Objekty',
        symbols: 'Symboly',
        flags: 'Vlajky',
        custom: 'Vlastní',
    },
    categorieslabel: 'Kategorie smajlíků', // Accessible title for the list of categories  
}

const ChatControls = ({text,showAddImage,isImageInput,onKeyDownHandle,innerRef,onPaste,handleOnChange,setSelectedText,OpenImages,setTextToPosition,sendMessage,selRange}) => {

    const [openEmoji, setOpenEmoji] = useState(false);

    return(
        <div className="write-message">

            {showAddImage ?
                <div className="add-images">
                    <div className="add-images-container">
                        <img src={addImage} />
                        <input type="file" name="images" multiple onChange={(e) => OpenImages(e)} />
                    </div>
                </div>
            :null}

            <ContentEditable onKeyDown={(e) => onKeyDownHandle(e,isImageInput)} innerRef={innerRef} tagName={"div"} html = {text} className={"message"} disabled={false} name="text" onPaste={(e) => onPaste(e,isImageInput)} onChange={(evt) => handleOnChange(evt,isImageInput)} />

            {!text && !isImageInput ?
                <div className="placeholder">{translate.t("PhotoDescription")}</div>
            :null}

            <div className="emotikons">
                <div className="emotikons-container" onMouseDown={() => {setSelectedText();setOpenEmoji(!openEmoji)}}>
                    <img src={emoji} />
                </div>

                {openEmoji ?
                    <>
                        <div className="emoji-overlay" onClick={() => {selRange = null; setOpenEmoji(false)}}></div>
                        <Picker 
                            style={{ position: 'absolute', top: '-355px', right: '5px', zIndex:2 }} 
                            native = {true}
                            title='' 
                            emoji='' 
                            showPreview={false} 
                            className="emoji-picker"
                            showSkinTones = {false} 
                            onSelect= {(emoji) => {
                                setTextToPosition(emoji.native,isImageInput);

                            }}
                            i18n = {emojiPickerLangOptions}
                        />
                    </>
                :null}
            </div>
            <div className="send">
                <div className="send-container" onClick={() => sendMessage(isImageInput)}>
                    <img src={send} />
                </div>
            </div>
        </div>
    )
}

export default ChatControls;