import Helper from '../../../Library/helper';
import Notification from '../../../Library/notification';
import {GET_GROUP,GET_GROUP_MEMBERS_DATA} from '../Queries/group';

class Group{

    constructor(props){
        this.props = props;
    }

    async getData(groupID,formData,setFormData){
        var data = await this.props.client.query({
            query:GET_GROUP,
            fetchPolicy: 'network-only',
            variables:{
                groupID
            }
            
        });

        setFormData({...formData,name:data.data.group.name});
    }

    addGroup(formData,addGroup){

        if(formData.name != ""){

            addGroup({
                variables:formData
            });                

        }else{

            let notify = new Notification();
            notify.setNotification(null,"Nevyplnili jste název skupiny.",false,true,this.props.client);
        }
        
    }

    formHandle(e,formData,setFormData){
	  
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;

        
        setFormData({...formData,[n]: v});
        
    }

}

export default Group;