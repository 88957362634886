/**
 * soubor se seznamem veškerých graphql dotazů pro změnu hesla
 */
import gql from 'graphql-tag';

export const GET_MY_VS_DATA = gql`
    query GetMyVSData($guideID:ID) {
        allGuidesCustomer(guideID: $guideID){
            customerID
            name
            surname
            astralName
            notReadByGuidePostCount
            membershipTo
            materialChangeDate
            firstContactWithGuide
            customerLastMaterial{
                longName
                materialID
                parentID
            }
        }
        allGuideMaterialsToOpen(lang:"cz"){
            materialID
            name
        }
    }
`;

export const CONFIRM_VS_CONTACT = gql`
    mutation ConfirmVsContact($customerID:ID) {
        confirmVsContact(customerID: $customerID)
    }
`;
