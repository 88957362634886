import gql from 'graphql-tag';

export const GET_CUSTOMER_VIDEO = gql`
    query CustomerVideo($hash: ID!,$lang:String){
        customerVideo(hash:$hash,lang:$lang){
            url
            videoID
            viewed
            lang
		}
		
	}
`;

export const GET_CUSTOMER_FIRST_VIDEO = gql`
    query FirstCustomerVideo($lang: String){
        firstCustomerVideo(lang:$lang){
            hash
            categoryID
            lang
            category{
                niceName
            }
		}
		
	}
`;

export const UPDATE_VIEWED_VIDEO = gql`
    mutation UpdateViewedVideo($videoID: ID!){
        updateViewedVideo(videoID:$videoID)  
    }
`;