import { useState } from "react";
import { SET_NOTIFICATION_IS_READ, GET_LAST_NOTIFICATIONS_BY_TIME } from "../Queries/notification";
import { GET_HEADER_DATA } from "../../../GlobalQueries/globalQueries";

const UseNotification = (client,notificationListVariables) => {

    const [selectedNotification, SetSelectedNotification] = useState(null);

    const SelectNotification = (notification) => {

        SetSelectedNotification(notification);

        if(notification && notification.isRead == 0)
        {
            client.mutate({ 
                mutation: SET_NOTIFICATION_IS_READ,
                variables:{
                    notificationID: notification.notificationID,
                }, 
            }).then(() => {

                UpdateNotReadNotificationCount("sub");
                
                try
                {
                    const { LastCustomerNotificationsByTime } = client.readQuery({ 
                        query: GET_LAST_NOTIFICATIONS_BY_TIME, 
                        variables: notificationListVariables
                    });

                    var arr = [...LastCustomerNotificationsByTime];
                    for(let i in arr)
                    {
                        if(arr[i].notificationID == notification.notificationID)
                        {
                            console.log("jop");
                            arr[i] = {...arr[i],isRead:1}
                        }
                    }

                    client.writeQuery({ 
                        query:GET_LAST_NOTIFICATIONS_BY_TIME,
                        variables: notificationListVariables,
                        data:{
                            LastCustomerNotificationsByTime:arr
                        } 
                    }); 

                }catch(err){
                    console.log(err)
                }

            });
        }
    }

    const UpdateNotReadNotificationCount = (type) => {

        const { customerContext } = client.readQuery({ query: GET_HEADER_DATA});
        var custContext = {...customerContext};

        if(type === "add")
            custContext.NotReadNotificationCount++;
        else
            custContext.NotReadNotificationCount--; 
            
        client.writeQuery({ 
            query:GET_HEADER_DATA,
            data:{
                customerContext:custContext
            } 
        }); 
    }

    return{
        selectedNotification,
        SelectNotification,
        UpdateNotReadNotificationCount
    }
}

export default UseNotification;