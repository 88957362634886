/**
 *  Pomocná třída s obecnými funkcemi
 */

class Helper{

    getApolloErrorText(error){

        error = JSON.stringify(error);
        error = JSON.parse(error);

        if(error.graphQLErrors[0])return error.graphQLErrors[0].message;
        if(error.networkError && error.networkError.result.errors){
            return error.networkError.result.errors[0].message;
        }

    }

    validateEmail(email) {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    SetTheme(theme)
    {
        if(theme == 1)
            document.body.classList.remove("theme-dark");
        if(theme == 2)
            document.body.classList.add("theme-dark");
        if(theme == 0)
        {

        }
    }

    IsiOS() {
        const toMatch = [
            /Android/i,
            /webOS/i,
            /iPhone/i,
            /iPad/i,
            /iPod/i,
            /BlackBerry/i,
            /Windows Phone/i
        ];
        
        return toMatch.some((toMatchItem) => {
            return navigator.userAgent.match(toMatchItem) || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
        });
    }

    IsSafari(){

        let userAgentString = navigator.userAgent;
      
        let chromeAgent = userAgentString.indexOf("Chrome") > -1;
        let safariAgent = userAgentString.indexOf("Safari") > -1;
        
        if ((chromeAgent) && (safariAgent)) safariAgent = false;
      
        return safariAgent;
      
    }

    GetDaysString(numberOfDays)
    {
        var dayString = "";
        switch(numberOfDays)
        {
            case 1: dayString = "den"; break;
            case 2:
            case 3: 
            case 4: dayString = "dny"; break;
            default: dayString = "dnů"; break;
        }

        return dayString;
    }

    GetMinutesFromTime(time)
    {
        const timeData = time.split(":");
        if(timeData[0] == "00")timeData[0] = 0;
        if(timeData[1] == "00")timeData[1] = 0;

        return parseInt(timeData[0]) * 60 + parseInt(timeData[1]);     
    }

    GetTimeFromMinutes(minuteTime)
    {
        var hours = Math.floor(minuteTime / 60);
        var minutes = minuteTime % 60;

        return ('00' + hours).slice(-2) + ":" + ('00' + minutes).slice(-2);
    }

}

export default Helper;