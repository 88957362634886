import { useState } from 'react';
import {GET_MY_VS_DATA,CONFIRM_VS_CONTACT} from '../Queries/myvs';
import { useMutation,withApollo } from 'react-apollo';

const useMyVs = (client) => {

    const [customerID,SetCustomerID] = useState(null);
    const [showNotify,SetShowNotify] = useState(false);

    const [confirmVsContact,{data,loading,error}] = useMutation(CONFIRM_VS_CONTACT,{
        onCompleted:(response) => {

            const { allGuidesCustomer } = client.readQuery({ query: GET_MY_VS_DATA });
            var arr = [...allGuidesCustomer];
            
            arr.forEach((item,index) => {
                if(item.customerID == customerID){
                    arr[index].firstContactWithGuide = 1;
                }
            });

            client.writeQuery({ 
                query:GET_MY_VS_DATA,
                data:{
                    allGuidesCustomer: arr
                } 
            });

            SetCustomerID(null);
        }
    })
    
    const ConfirmContact = (act) => {
        if(customerID && act)
        {
            confirmVsContact({
                variables:{
                    customerID
                }
            })
        }

        SetShowNotify(false);
    }

    const ShowConfirmNotification = (customerID) => {
        SetCustomerID(customerID);
        SetShowNotify(true);
    }
    
    return{
        showNotify,
        error,
        loading,
        ConfirmContact,
        ShowConfirmNotification,
    }
}

export default  useMyVs;
