import React,{ useState } from 'react';
import { withApollo,useQuery } from 'react-apollo';
import {GET_CUSTOMERS_EVS_POINTS} from '../Queries/customer';
import Loading from '../../../GlobalComponents/Loading';
import NoItems from '../../../GlobalComponents/NoItems';
import moment from 'moment';
import Error from '../../../GlobalComponents/Error';
import Helper from '../../../Library/helper';
import translate from '../../Translations/index';

const EVSPoints = (props) => {

    let years = [];
    let yearToday = moment().format("YYYY");
    for(let i = 2021; i <= parseInt(yearToday)+1; i++){
        years.push(i);
    }

    const [month,setMonth]                         = useState(moment().format("M"));
    const [year,setYear]                           = useState(moment().format("YYYY"));
    const [selectedCustomers,setSelectedCustomers] = useState({points:[]});

    var variablesList = {
        customerID:props.customer.customerID,
        month:parseInt(month),
        year:parseInt(year),
    }

    const {data,loading,error} = useQuery(GET_CUSTOMERS_EVS_POINTS,{
        variables:variablesList,
        fetchPolicy: 'network-only',
    });

    var totalPoints = 0;

    var err = "";
    if(error){
        const helper = new Helper(); 
        //err = helper.getApolloErrorText(error || deleteError); 
    }

    return(
        
        <div className="card main">
            
            <div className="card-header d-flex align-items-center">
                <select value={year} className="form-control thiner mr-1 ml-auto" onChange={(e) => setYear(e.target.value)}> 
                    {years.map((item,index) => (
                        <option key={index} value={item}>{item}</option>
                    ))} 
                </select>
                <select value={month} className="form-control thiner" onChange={(e) => setMonth(e.target.value)}>
                    <option value="1">{translate.t("Leden")}</option>
                    <option value="2">{translate.t("Únor")}</option>
                    <option value="3">{translate.t("Březen")}</option>
                    <option value="4">{translate.t("Duben")}</option>
                    <option value="5">{translate.t("Květen")}</option>
                    <option value="6">{translate.t("Červen")}</option>
                    <option value="7">{translate.t("Červenec")}</option>
                    <option value="8">{translate.t("Srpen")}</option>
                    <option value="9">{translate.t("Září")}</option>
                    <option value="10">{translate.t("Říjen")}</option>
                    <option value="11">{translate.t("Listopad")}</option>
                    <option value="12">{translate.t("Prosinec")}</option>
                </select>
            </div>
            <div className="card-body">

                {loading ?
                    <Loading />
                :
                    (err != "" ?
                        <Error text={err} />
                    :
                        <div className="data-list">
                                                
                            <div className="data-list-item header">
                                <div className="data-list-item-content">
                                    <div className="text-left static">Datum připsání</div>
                                    <div className="text-left">Info</div>
                                    <div className="text-right static-small">Body</div>
                                </div>
                            </div>
                            
                            {data.allCustomerEVSPoints && data.allCustomerEVSPoints.map((item,index) => {
                                    
                                totalPoints += item.points;

                                var checked = false;
                                for(let i in selectedCustomers.points){
                                    if(selectedCustomers.points[i] == item.customerEVSPointID)checked = true;
                                }
                                                                                

                                return (
                                
                                    <div key={index} className="data-list-item-content">
                                        <div className="text-left static">{moment(item.dateAdd).format("DD.MM.YYYY")}</div>
                                        <div className="text-left">{item.info}</div>
                                        <div className="text-right static-small">{item.points}</div>
                                        
                                        
                                    </div>
                                            
                                )
                                
                            })} 

                                                
                            {data.allCustomerEVSPoints && data.allCustomerEVSPoints.length == 0 ?
                                <NoItems text={"V tomto měsíci se zde nenachází žádné body."} />
                            :
                                <div className="data-list-item footer">
                                    <div className="data-list-item-content">
                                        <div className="text-left static"><strong>Celkem</strong></div>
                                        <div className="text-left"></div>
                                        <div className="text-right static"><strong>{totalPoints}</strong></div>
                                        
                                        
                                    </div>
                                </div>
                            }
                            
                        </div>
                    )
                }
                
            </div>
        </div>
                
                
    );

    

}

export default withApollo(EVSPoints);