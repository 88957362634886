import React,{ Component,Fragment, useEffect,useState } from 'react';
import {withApollo,useQuery,useMutation } from 'react-apollo';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import Notification from '../../../Library/notification';
import CustomerLib from '../Library/customer';
import {GET_CUSTOMER_DETAILS,UPDATE_CUSTOMER_DETAILS} from '../Queries/customer';
import translate from '../../Translations/index';
import user from '../../../Public/Images/user.svg';
import {SERVER_URL} from '../../../Config/index';
import {GET_HEADER_DATA} from '../../../GlobalQueries/globalQueries';


const CustomerDetials = (props) => {

    var customerLib = new CustomerLib(props);

    const [mainPhoto,setMainPhoto] = useState("");
    const [formData,setFormData] = useState({
        name:'',
		surname:'',
        astralName:'',
        roomName:'',
        company:'',
        ic:'',
        dic:'',
        street:'',
        city:'',
        zip:'',
        customerID:0,
        bankAccount:'',
        iban:'',
        swift:'',
        introduction:'',
        myMotivation:'',
        actualJob:'',
        idealJob:'',
        iAmGoodAt:'',
        aboutMe:'',
        photo:null
    });

    const {data,loading,error} = useQuery(GET_CUSTOMER_DETAILS,{
        fetchPolicy: 'network-only',
    });

    const [updateCustomerDetails,{error:addError}] = useMutation(UPDATE_CUSTOMER_DETAILS,{
        onCompleted: (dataBack) => {

            let notify = new Notification();
            notify.setNotification(null,"Úspěšně uloženo",true,false,props.client);

            const { customerContext } = props.client.readQuery({ query: GET_HEADER_DATA});

            var dataCC = {...customerContext};
            if(dataBack.updateCustomerDetials != "")dataCC.photo = dataBack.updateCustomerDetials;

            props.client.writeQuery({ 
                query:GET_HEADER_DATA,
                data:{
                    customerContext: dataCC
                } 
            });
            
        }
    })
    
    useEffect(()=>{

        if(data){

            setFormData({
                customerID:   data.customerContext.customerID,
                name:         data.customerContext.name,
		        surname:      data.customerContext.surname,
                astralName:   data.customerContext.astralName,
                roomName:     data.customerContext.roomName,
                company:      data.customerContext.company ? data.customerContext.company : data.customerContext.name + " " + data.customerContext.surname,
                ic:           data.customerContext.ic,
                dic:          data.customerContext.dic,
                street:       data.customerContext.street,
                city:         data.customerContext.city,
                zip:          data.customerContext.zip,
                bankAccount:  data.customerContext.bankAccount,
                iban:         data.customerContext.iban,
                swift:        data.customerContext.swift,
                myMotivation: (data.customerContext.myMotivation == null ? "" : data.customerContext.myMotivation),
                introduction: data.customerContext.introduction,
                actualJob:    data.customerContext.actualJob,
                idealJob:     data.customerContext.idealJob,
                iAmGoodAt:    (data.customerContext.iAmGoodAt == null ? "" : data.customerContext.iAmGoodAt),
                aboutMe:      (data.customerContext.aboutMe == null ? "" : data.customerContext.aboutMe),
            })

            var maPhoto = "";
            if(data.customerContext.photo) maPhoto = SERVER_URL + "/Public/Images/Avatar/stredni_" + data.customerContext.photo;
            
            setMainPhoto(maPhoto);
        }

    },[data])	
  		  
    return (
    	<div id="settings" className="whole-container">		
    		<div className="row">
    			<div className="col-sm one">
					
                    <div className="card main">
                        
                        <div className="card-header d-flex align-items-center">
                            {translate.t('PersonalDetails')}
                            <button className="btn btn-primary btn-thiner ml-auto" onClick={() => customerLib.changeCustomerDetails(formData,updateCustomerDetails,(data && data.customerContext))}>{translate.t('SaveChanges')}</button>
                        </div>
                        <div className="card-body">

                            {error ?
                                <Error text={error} />
                            :
                                <Fragment>
                                    {loading ?
                                        <Loading />
                                    :
                                        <>
                                        <div className="row">

                                            <div className="col-12 col-md-6 col-lg-4">

                                                <div className="avatar-image mb-3">
                                                    <img src={(mainPhoto != "" ? mainPhoto : user)} />
                                                </div>

                                                
                                                <div className="form-group input-group">
                                                    <div className="custom-file">

                                                                                                                
                                                        <input
                                                            type="file"
                                                            className="custom-file-input"
                                                            id="customFile"
                                                            name="file"
                                                            required
                                                            multiple
                                                            onChange={(e) => customerLib.openImage(e,formData,setMainPhoto,setFormData)}
                                                        />
                                                        <label className="custom-file-label" htmlFor="customFile">Vyberte fotku</label>
                                                    </div>
                                                </div>

                                                


                                            </div>
                                            <div className="col-12 col-md-6 col-lg-8">

                                                <div className="row">
                             
                                                    <div className="col-12 col-sm-6 col-lg-6 col-xl-4">
                                                        <label className="input-label">*{translate.t('Name')}</label>
                                                        <div className="form-group">
                                                            <input className="form-control" type="text" name="name" value={formData.name} onChange={(e) => customerLib.formHandle(e,formData,setFormData)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-lg-6 col-xl-4">
                                                        <label className="input-label">*{translate.t('Surname')}</label>
                                                        <div className="form-group">
                                                            <input className="form-control" type="text" name="surname" value={formData.surname} onChange={(e) => customerLib.formHandle(e,formData,setFormData)} />
                                                        </div>
                                                    </div>

                                                    {data.customerContext.customerTypeID != 3 ?

                                                        <>
                                                            <div className="col-12 col-sm-6 col-xl-4">
                                                                <label className="input-label">{translate.t('RoomName')}</label>
                                                                <div className="form-group">
                                                                    <input className="form-control" type="text" name="roomName" value={formData.roomName} onChange={(e) => customerLib.formHandle(e,formData,setFormData)} />
                                                                </div>
                                                            </div>

                                                        
                                                            <div className="col-12 col-sm-6 col-lg-6">
                                                                <label className="input-label">{translate.t('AstralName')}</label>
                                                                <div className="form-group">
                                                                    <input className="form-control" type="text" name="astralName" value={formData.astralName} onChange={(e) => customerLib.formHandle(e,formData,setFormData)} />
                                                                </div>
                                                            </div>
                                                        </>
                                                    :null}
                                                    

                                                </div>

                                                <h2>Fakturační údaje</h2>
                                                
                                                <div className="row">

                                                    <div className="col-12">
                                                        <label className="input-label">{translate.t('NameAndSurname')} ({translate.t('CompanyName')})</label>
                                                        <div className="form-group">
                                                            <input className="form-control" type="text" name="company" value={formData.company} onChange={(e) => customerLib.formHandle(e,formData,setFormData)} />
                                                        </div>
                                                    </div>
                                                                                 
                                                    <div className="col-12 col-sm-6 col-xl-5">
                                                        <label className="input-label">{translate.t('Street')}</label>
                                                        <div className="form-group">
                                                            <input className="form-control" type="text" name="street" value={formData.street} onChange={(e) => customerLib.formHandle(e,formData,setFormData)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-xl-5">
                                                        <label className="input-label">{translate.t('City')}</label>
                                                        <div className="form-group">
                                                            <input className="form-control" type="text" name="city" value={formData.city} onChange={(e) => customerLib.formHandle(e,formData,setFormData)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-xl-2">
                                                        <label className="input-label">{translate.t('Zip')}</label>
                                                        <div className="form-group">
                                                            <input className="form-control" type="text" name="zip" value={formData.zip} onChange={(e) => customerLib.formHandle(e,formData,setFormData)} />
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="col-12 col-sm-6 col-xl-4">
                                                        <label className="input-label">{translate.t('BankAccount')}</label>
                                                        <div className="form-group">
                                                            <input className="form-control" type="text" name="bankAccount" value={formData.bankAccount} onChange={(e) => customerLib.formHandle(e,formData,setFormData)} />
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-sm-6 col-xl-4">
                                                        <label className="input-label">{translate.t('Swift')}</label>
                                                        <div className="form-group">
                                                            <input className="form-control" type="text" name="swift" value={formData.swift} onChange={(e) => customerLib.formHandle(e,formData,setFormData)} />
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-sm-6 col-xl-4">
                                                        <label className="input-label">{translate.t('Iban')}</label>
                                                        <div className="form-group">
                                                            <input className="form-control" type="text" name="iban" value={formData.iban} onChange={(e) => customerLib.formHandle(e,formData,setFormData)} />
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-sm-6 col-xl-4">
                                                        <label className="input-label">{translate.t('IC')}</label>
                                                        <div className="form-group">
                                                            <input className="form-control" type="text" name="ic" value={formData.ic} onChange={(e) => customerLib.formHandle(e,formData,setFormData)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-xl-4">
                                                        <label className="input-label">{translate.t('DIC')}</label>
                                                        <div className="form-group">
                                                            <input className="form-control" type="text" name="dic" value={formData.dic} onChange={(e) => customerLib.formHandle(e,formData,setFormData)} />
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                                
                                        </div>

                                        {data.customerContext.customerTypeID != 3 ?
                                            <>

                                                <hr />

                                                <div className="row">
                                                                            
                                                    <div className="col-12">
                                                        <label className="input-label">{translate.t('MyMotivation')}</label>
                                                        <div className="form-group">
                                                            <input className="form-control" type="text" name="myMotivation" value={formData.myMotivation} onChange={(e) => customerLib.formHandle(e,formData,setFormData)} />
                                                        </div>
                                                    </div>

                                                    <div className="col-12">
                                                        <label className="input-label">{(data.customerContext.courseID == 0 || (data.customerContext.courseID != 0 && data.customerContext.lastCompletedCustomerPayment.amount != 0) ? "*" : "")}{translate.t('Introduction')}</label>
                                                        <div className="form-group">
                                                            <div className="input-group">
                                                                <input className="form-control" type="text" name="introduction" value={formData.introduction} onChange={(e) => customerLib.formHandle(e,formData,setFormData)} />
                                                                <div className="input-group-append">
                                                                    <span className={"input-group-text " + (formData.introduction.length && formData.introduction.length > 30 ? 'red' : '')}>{30 - (formData.introduction.length ? formData.introduction.length : 0)}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12">
                                                        <label className="input-label">{translate.t('ActualJob')}</label>
                                                        <div className="form-group">
                                                            <input className="form-control" type="text" name="actualJob" value={formData.actualJob} onChange={(e) => customerLib.formHandle(e,formData,setFormData)} />
                                                        </div>
                                                    </div>

                                                    <div className="col-12">
                                                        <label className="input-label">{translate.t('IdealJob')}</label>
                                                        <div className="form-group">
                                                            <input className="form-control" type="text" name="idealJob" value={formData.idealJob} onChange={(e) => customerLib.formHandle(e,formData,setFormData)} />
                                                        </div>
                                                    </div>

                                                    <div className="col-12">
                                                        <label className="input-label">{translate.t('IamGoodAt')}</label>
                                                        <div className="form-group">
                                                            <input className="form-control" type="text" name="iAmGoodAt" value={formData.iAmGoodAt} onChange={(e) => customerLib.formHandle(e,formData,setFormData)} />
                                                        </div>
                                                    </div>

                                                    <div className="col-12">
                                                        <label className="input-label">{translate.t('AboutMe')}</label>
                                                        <div className="form-group">
                                                            <textarea className="form-control" name="aboutMe" onChange={(e) => customerLib.formHandle(e,formData,setFormData)} value = {formData.aboutMe}  ></textarea>
                                                        </div>
                                                    </div>
                                                    
                                                        
                                                </div>
                                            </>
                                        :null}

                                        </>
                                    }
                                </Fragment>
                            }
                                        

                        </div>
                        
                    </div>
								
    			</div>
    		</div>    			
        </div>
    );

}

export default withApollo(CustomerDetials);
