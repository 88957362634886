import React,{ Component,Fragment } from 'react';
import {Mutation} from 'react-apollo';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import Auth from '../Library/auth';
import {SAVE_NEW_PASSWORD} from '../Queries/changePassword';
import { withApollo } from 'react-apollo';
import logo from '../../../Public/Images/logo.svg';
import key from '../../../Public/Images/Icons/key.webp';
import keyA from '../../../Public/Images/AnimatedIcons/key.webp';
import translate from '../../Translations/index';

const INITIAL_STATE = {
	
	password:'',
	checkPassword:'',
	error:"",
	message:"",
	loading:false,
    keyIcon:key,
    keyIcon2:key
}

class NewPassword extends Component {
		
  constructor(props){
	    
	  super(props);	 
      this.state = {...INITIAL_STATE};
      this.goBack = this.goBack.bind(this);
 
  }	

  componentDidMount(){
	  this.auth = new Auth(this);
  }

  goBack(){ 
    this.props.history.push("/");
  }
    
  
  render() {

    const {message} = this.state;
    const passCode = this.props.match.params.code;
		  
    return (
    	<div id="login" className="admin">

            <div className="logo">
                <img alt={translate.t('AstralProjection')} src={logo} />
            </div>

        	<div className="card">
				
				<div className="card-header">{translate.t('AstralProjection')}</div>
				<div className="card-body">

					<Mutation
						mutation = {SAVE_NEW_PASSWORD}
						update = {async (cache, response) => {

							if(response.data.saveNewPassword != ""){
                                this.setState({error:response.data.saveNewPassword});
                                setTimeout(() => this.setState({'error':''}),3000);
							}else{	
                                this.setState({message:translate.t('SuccessfullyChanged')});
							}

						}}
					>
						{
							(saveNewPassword,response) => {

                                const {checkPassword,password,error} = this.state;
						
								return(
									<div>
                                        {response.error ?
                                            <Error text={response.error} />
                                        :
                                        <Fragment>
                                            {response.loading ?
                                                <Loading />
                                            :
                                            <Fragment>

                                                {message == "" ?
                                                    <>
                                                        <div className="form-group fs-14">
                                    
                                                            {translate.t('ChooseYourPwd')}
                                    
                                                        </div>
                                                        <div className="form-group">
                                                            
                                                            <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text" ><img alt="" src={this.state.keyIcon} /></span>
                                                            </div>
                                                            <input
                                                                onMouseEnter={() => this.setState({keyIcon:keyA})}
                                                                onMouseOut={() => this.setState({keyIcon:key})}
                                                                onChange={event => this.setState({'password':event.target.value})}
                                                                value={password}
                                                                type="password" className="form-control" placeholder={translate.t('NewPassword')} aria-label={translate.t('NewPassword')} />
                                                            </div>

                                                        </div>
                                                        <div className="form-group">
                                                                                    
                                                            <div className="input-group">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text" ><img alt="" src={this.state.keyIcon2} /></span>
                                                            </div>
                                                            <input
                                                                onMouseEnter={() => this.setState({keyIcon2:keyA})}
                                                                onMouseOut={() => this.setState({keyIcon2:key})}
                                                                onChange={event => this.setState({'checkPassword':event.target.value})}
                                                                value={checkPassword}
                                                                type="password" placeholder={translate.t('RepeatNewPwd')} className="form-control" aria-label={translate.t('RepeatNewPwd')} />
                                                            </div>
                                                            
                                                        </div>
                                                    </>
                                                :null}

                                                <div>
                                                    
                                                    {message != "" ?
                                                        <button onClick={() => this.goBack()} className="btn btn-primary btn-blue btn-block">{translate.t('BackToLogin')}</button> 
                                                    :
                                                        <button onClick={() => this.auth.saveNewPassword(saveNewPassword,passCode,translate.locale)} className="btn btn-primary btn-blue btn-block">{translate.t('Save')}</button> 
                                                    }
                                                </div>

                                                {error && <div className="alert alert-danger mt-3 mb-0 text-center">{error}</div>}
                                                {message && <div className="alert alert-success mt-3 mb-0 text-center">{message}</div>}
                                            </Fragment>
                                            }
                                        </Fragment>
                                        }
                                    </div>
								)
							}
						}
					</Mutation>
    			</div>
    		</div>    			
        </div>
    );
  }
}

export default withApollo(NewPassword);
