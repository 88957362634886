import translate from '../../Translations/index';
import DatePicker from '../../Reservations/Components/Datepicker';
import moment from 'moment';

const FilterDaily = ({
    filterData,
    SetFilter,
    CloseFilter
}) => {
    return(
        <>
            <div className="filter-panel">
                <label className="input-label">{translate.t("ShowPosts")}</label>
                <div className="row">
                    <div className="col-12 col-sm-6 col-md-4 col-xl-3">
                        <div className="form-group">
                            <DatePicker 
                                value = {filterData.dateFrom ? moment(filterData.dateFrom).format("DD.MM.YYYY") : ""}
                                showLegend = {false}
                                showPast = {true}
                                placeholder = {translate.t("DateFrom")}
                                OnChange = {(dateString,date) => SetFilter("dateFrom",date)}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-xl-3">
                        <div className="form-group">
                            <DatePicker 
                                value = {filterData.dateTo ? moment(filterData.dateTo).format("DD.MM.YYYY") : ""}
                                showLegend = {false}
                                showPast = {true}
                                placeholder = {translate.t("DateTo")}
                                OnChange = {(dateString,date) => SetFilter("dateTo",date)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div 
                onClick = {() => CloseFilter()}
                className="filter-panel-overlay"
            ></div>
        </>
    )
}

export default FilterDaily;