import Error from "./Error";
import translate from '../Modules/Translations/index';

const NoInternetConnection = () => {

    return (
        <div id="no-internet">
            <div className="text-center">{translate.t("OfflineInternet")}</div>
        </div>
    )
}

export default NoInternetConnection;