import React,{Fragment,useEffect,useState} from 'react';
import {useMutation,withApollo} from 'react-apollo';
import Modal from 'react-modal';
import translate from '../../Translations/index';
import Group from '../Library/group';
import {ADD_EDIT_GROUP} from '../Queries/group';
import {GET_VS_LIST_DATA} from '../Queries/customer';
import Notification from '../../../Library/notification';
import close from '../../../Public/Images/Icons/close.webp';

const AddEditGroup = (props) => {

    var groupLib = new Group(props);

    const [formData, setFormData] = useState({
        customerID : props.customerID,
        groupID:  (props.groupID ? props.groupID : 0),
        name: ""
    });

    const [addGroup,{error,loading}] = useMutation(ADD_EDIT_GROUP,{
        onCompleted:(backData) =>{
            
            const {allCustomersWithActiveMembership,customersWithActiveMembershipCount,allCustomerGroups} = props.client.readQuery({ query: GET_VS_LIST_DATA,variables:props.variables});
            
            if(props.groupID && props.groupID != 0){

                var arr = [...allCustomerGroups];
                for(let i = 0; i < arr.length; i++){
                    if(arr[i].groupID == backData.addEditGroup.groupID){
                        arr[i].group.name = backData.addEditGroup.group.name;
                    }
                }

                props.client.writeQuery({ 
                    query:GET_VS_LIST_DATA,
                    variables:props.variables,
                    data:{
                        allCustomersWithActiveMembership,
                        customersWithActiveMembershipCount,
                        allCustomerGroups:arr
                    } 
                }); 

            }else{
                var arr = [backData.addEditGroup,...allCustomerGroups]; 
                
                props.client.writeQuery({ 
                    query:GET_VS_LIST_DATA,
                    variables:props.variables,
                    data:{
                        allCustomersWithActiveMembership,
                        customersWithActiveMembershipCount,
                        allCustomerGroups:arr
                    } 
                }); 

                props.selectGroup(backData.addEditGroup);
            }

            let notify = new Notification();
            notify.setNotification(null,translate.t("SuccessfullySaved"),true,false,props.client);

            props.showAddGroup(false);

        }
    });

    useEffect(() => {

        if(props.groupID && props.groupID != 0){
            groupLib.getData(props.groupID,formData,setFormData);
        }

    },[])

    return(
        
        <Modal
            className="Modal__Bootstrap modal-lg modal-dialog"
            closeTimeoutMS={150}
            isOpen={true}
            onRequestClose={() => props.showAddGroup(false)}
        >
            <div className="modal-content">

                <div className="modal-header">
                    {(props.groupID && props.groupID != 0 ? translate.t("EditGroup") : translate.t("AddGroup"))}
                    <img src={close} className="close" onClick={() => props.showAddGroup(false)} />
                </div>
                <div className="modal-body">

                    <div>
                        <label className="input-label">{translate.t('GroupName')}</label>
                        <div className="form-group">
                            <input className="form-control" type="text" name="name" value={formData.name} onChange={(e) => groupLib.formHandle(e,formData,setFormData)} />
                        </div>
                    </div>
              
                </div>

                <div className="modal-footer"> 
                                    
                    <button type="button" className="btn btn-primary flex-fill" onClick={() => groupLib.addGroup(formData,addGroup)}>{(props.groupID && props.groupID != 0 ? translate.t("Edit") : translate.t("Add"))}</button>
                    <button type="button" className="btn btn-danger" onClick={() => props.showAddGroup(false)}>{translate.t("Close")}</button>
                </div>

            </div>
        </Modal>
           
    );

}

export default withApollo(AddEditGroup);