/**
 * soubor se seznamem veškerých graphql dotazů pro změnu hesla
 */
import gql from 'graphql-tag';

export const GET_CUSTOMER_MATERIAL_DATA = gql`
    query GetMaterialData{
        customerContext{
            customerID
            domainID
            membershipTo
            customerTypeID
            regCode
            domain{
                lang
            }
            customerSortedMaterials{
                materialID
                name
                description
                parentID
                subMaterial{
                    materialID
                    name
                    description
                    parentID
                    subMaterial{
                        materialID
                        name
                        description
                        parentID
                        subMaterial{
                            materialID
                            name
                            description
                            parentID
                            subMaterial{
                                materialID
                                name
                                description
                                parentID
                                subMaterial{
                                    materialID
                                    name
                                    description
                                    parentID
                                    subMaterial{
                                        materialID
                                        name
                                        description
                                        parentID
                                    }
                                }
                            }
                        }
                    }
                } 
            }
        }
        
    }
`;