class EmailSmsNotify{

    constructor(parent){
        this.parent = parent;
    }

    setEventCategory(eventCategoryID,selectedEventCategories, setSelectedCategories){

        var checked = false;
        var arr = [...selectedEventCategories];

        for(let i in arr){
            if(arr[i] == eventCategoryID){
                arr.splice(i,1);
                checked = true;
            }
        }

        if(!checked){
            arr.push(eventCategoryID);
        }

        setSelectedCategories(arr);

    }

}

export default EmailSmsNotify;


