import { useState,createContext,useContext } from 'react';

const themeContext = createContext();

export const ThemeProvider = ({children}) => {

    const socket = UseProviderTheme();

    return(
        <themeContext.Provider value={socket}>
            {children}
        </themeContext.Provider>
    )
}

export const UseTheme = () => {
    return useContext(themeContext);
}

const UseProviderTheme = () => {

    const [theme, SetTh] = useState(null);
        
    const SetTheme = (theme) => {

        if(theme == 1)
            document.body.classList.remove("theme-dark");
        
        if(theme == 2)
            document.body.classList.add("theme-dark");

        if(theme == 0)
        {

        }

        SetTh(theme);
    }
    
    return({
        theme,
        SetTheme
    })

}
