import Observer from "./Observer";
import { useScript } from '../../../Library/hooks';
import { useState,useEffect,useRef } from "react";
import Error from "../../../GlobalComponents/Error";
import Helper from "../../../Library/helper";

const ObserverWrapper = ({match}) => {

    const helper = new Helper();

    const hash = match.params.hash;
    
    const [showObserver, SetShowObserver] = useState(false);
    const [error, SetError] = useState("");
    const [stream, SetStream] = useState(null);

    const {lib} = useScript("https://meet.jit.si/libs/lib-jitsi-meet.min.js","JitsiMeetJS");

    useEffect(() => {

        if(lib.JitsiMeetJS)
        {
            if(helper.IsiOS())
            {
                navigator.mediaDevices
                .getUserMedia({audio:true})
                .then(function (stream) {
                    SetStream(stream);
                    SetShowObserver(true);
                })
                .catch(function (error) {
                    SetError("Je potřeba povolit mikrofon pro tuto konferenci")
                })
            }
            else
                SetShowObserver(true);
        } 
    },[lib.JitsiMeetJS])

    useEffect(() => {

        return () => {
            if(helper.IsiOS())
            {
                SetStream((prewvValue) => {
                    prewvValue.getAudioTracks().forEach((track) => { 
                        track.stop(); 
                    }); 
                }) 
            }
        }
    },[])

    return(
        <>
            {showObserver ?
                <Observer roomName={hash} JitsiMeetJS = {lib.JitsiMeetJS} />
            :null}

            {error ?
                <div className="whole-container">
                    <Error text={error} className="text-center" />
                </div>
            :null}
        </>
    )

}

export default ObserverWrapper;