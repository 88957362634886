import moment from 'moment';
import { useState } from 'react';
import { useEffect } from 'react';

var interval;

const CountDown = ({dateTo,OnFinished}) => {

    const [time , SetTime] = useState(null);

    useEffect(() => {

        const from = moment().unix();
        const to = moment(dateTo).unix();

        var seconds = to - from;
        
        ShowCountDown((seconds >= 0 ? seconds : 0));
        
        interval = setInterval(() => {

            seconds = seconds - 1;
            if(seconds >= 0)
                ShowCountDown(seconds);
            else
            {
                clearInterval(interval);
                if(OnFinished)OnFinished()
            }
            

        },1000)

    },[])

    const ShowCountDown = (seconds) => {

        var hours, minutes, secs;

        hours   = parseInt(seconds / 3600);
        minutes = seconds % 3600;
        secs    = minutes % 60;
        minutes = parseInt(minutes / 60);

        SetTime(LeadingZeros(hours,2) + ":" + LeadingZeros(minutes,2) + ":" + LeadingZeros(secs,2));

    }

    const LeadingZeros = (num, totalLength) => {
        return String(num).padStart(totalLength, '0');
    }

    return(
        <div className="count-down-time">
            {time}
        </div>
    )
}

export default CountDown;