import Helper from "../Library/helper";
import moment from "moment";
import { useEffect,useState } from "react";
import CountDown from "../GlobalComponents/CountDown";
import translate from '../Modules/Translations/index';

const MAX_DAYS_TO_SUBSCRIBE = 2;

const DaysToSubscribeInfo = ({membershipTo,OnSetDays}) => {

    const helper = new Helper();
    const [daysToSubscribe, SetDaysToSubscribe] = useState(-1);

    useEffect(() => {

        if(membershipTo)
        {
            var from = moment(membershipTo, "YYYY-MM-DD").add(MAX_DAYS_TO_SUBSCRIBE,"days");
            var to = moment().startOf("day");

            var days = moment.duration(from.diff(to)).asDays();

            SetDaysToSubscribe(days);
            if(OnSetDays) OnSetDays(days);
        }
        
    },[membershipTo])

    return(
        <>
            {daysToSubscribe >= 0 ?
                <div className="alert alert-warning text-center">
                    {translate.t("ExtendMembershipText1")} 
                    <div>
                        
                        <CountDown
                            dateTo = {moment().add(daysToSubscribe,"days").format("YYYY-MM-DD 23:59:59")}
                            OnFinished = {() => window.location.reload()}
                        />
                        
                    </div>
                    {translate.t("ExtendMembershipText2")} <a target="_blank" href="https://www.astralniakademie.cz">www.AstralniAkademie.cz</a>   
                </div>
            :
                <div className="alert alert-warning text-center">{translate.t("ExtendMembershipText3")} <a target="_blank" href="https://www.astralniakademie.cz">www.AstralniAkademie.cz</a> {translate.t("ExtendMembershipText4")}</div>
            }
        </>
    )
}

export default DaysToSubscribeInfo;