import { useState, useEffect } from 'react';

export function useLongPress(callback, ms = 300,disableSelection = false) 
{
  const [longPress, SetLongPress] = useState({
    startLongPress:false,
    e:null
  });

  useEffect(() => {
    let timerId;
    if (longPress.startLongPress) {
      timerId = setTimeout(() => callback(longPress.e), ms);
    } else {
      clearTimeout(timerId);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [callback, ms, longPress]);

  return {
    onMouseDown: (e) => {e.persist();SetLongPress({startLongPress:false,e})},
    onMouseUp: (e) => {e.persist();SetLongPress({startLongPress:false,e})},
    onMouseLeave: (e) => {e.persist();SetLongPress({startLongPress:false,e})},
    onTouchStart: (e) => {
      
      e.persist();
      if(disableSelection)
      {
        e.target.closest(".current-message-container").classList.add("disable-select");
      }

      SetLongPress({startLongPress:true,e});
    },
    onTouchMove: (e) => {
      e.persist();
      SetLongPress({startLongPress:false,e});
      
    },
    onTouchEnd: (e) => {
      e.persist();
      if(disableSelection)
      {
        e.target.closest(".current-message-container").classList.remove("disable-select");
      }
      SetLongPress({startLongPress:false,e});
      
    },
  };
}

export const useScript = (url,name) => {

    const [loaded,SetLoaded] = useState(false);
    const [lib, SetLib] = useState({})

    useEffect(() => {
        const script = document.createElement('script');

        script.src = url;
        script.onload = () => SetLib({ [name]: window[name] })

        document.body.appendChild(script);

        SetLoaded(true);

        return () => {
          document.body.removeChild(script);
        }
    }, []);

    return{
      loaded,
      lib
    }

};