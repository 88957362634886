import translate from '../../Translations/index';
import React,{forwardRef,useState,useEffect} from 'react';

const QuestionnaireRadio = forwardRef((props,ref) => {

    const [value, setValuet] = useState("");

    const setData = (value) => {
        setValuet(value);
        if(props.callBack)props.callBack(value);
    }

    useEffect(() => {
        if(props.value){
            setValuet(props.value);
        }
    },[])

    return(
        <div className="step" ref={ref}>
            <label className="input-label">{props.question}</label>
            <div className="input">
                {props.items.map((item,index) => {

                    var checked = false;
                    if(value == item.questionnaireStepItemID){
                        checked = true;
                    }
                    

                    return(

                        <div>
                            <div key={index} className="form-check form-check-inline">
                                
                                <input disabled={props.disabled} id={"r-" + props.questionnaireStepID + "-" + item.questionnaireStepItemID} className="form-check-input" checked={checked} type="radio" name={"r-" + props.questionnaireStepID} onChange={(e) => setData(item.questionnaireStepItemID)} /> 
                                
                                <label htmlFor={"r-" + props.questionnaireStepID + "-" + item.questionnaireStepItemID} className={"form-check-label" + (checked && props.disabled ? " selected" : (props.disabled ? " normal" : ""))} >
                                    {item.text}
                                </label>
                            </div>
                        </div>
                    )

                })}
            </div>
        </div>
    )

})

export default QuestionnaireRadio;