import gql from 'graphql-tag';

export const GET_COMPLETE_QUESTIONNAIRE_STEPS = gql`
query GetCompleteQuestionnaireSteps($lang:String,$customerID:ID,$questionnaireID:ID){
    getCompleteQuestionnaireSteps(lang:$lang,withDeleted:false,questionnaireID:$questionnaireID){
        questionnaireStepID
        questionTypeID
        lang
        question
        items{
            questionnaireStepItemID
            text
        }
    } 
    customerQuestionnaireSteps(customerID:$customerID,questionnaireID:$questionnaireID){
        questionnaireStepID
        text
        checkboxQuestionnaireStepItemIDs
        radioQuestionnaireStepItemID
        questionnaireStep{
            questionTypeID
        }
    }
    questionnaire(lang:$lang,questionnaireID:$questionnaireID){
        welcomeMessage
    }

}
`;

export const SAVE_QUESTIONNAIRE_STEPS = gql`
    mutation SaveQuestionnaireSteps($customerID:ID,$questionnaireID:ID,$data:SaveQuestionnaireSteps){
        saveQuestionnaireSteps(customerID:$customerID,questionnaireID:$questionnaireID,data:$data) 
    }
`;

export const SET_QUESTIONNAIRE_STEPS_COMPLETED = gql`
    mutation SetQuestionnaireStepsCompleted($customerID:ID){
        setQuestionnaireStepsCompleted(customerID:$customerID) 
    }
`;
