import Loading from '../../../GlobalComponents/Loading';
import translate from '../../Translations/index';
import { GET_DAILY_SETTINGS,SAVE_CUSTOMER_DAILY_SETTINGS } from '../Queries/daily';
import { useQuery,useMutation, useApolloClient } from 'react-apollo';
import SwitchInput from '../../../GlobalComponents/SwitchInput';
import { useEffect, useState } from 'react';
import Notification from '../../../Library/notification';

const DailySettings = () => {

    const client = useApolloClient();

    const [customerDailySettings,SrtCustomerDailySettings] = useState([]);

    const {data,loading,refetch} = useQuery(GET_DAILY_SETTINGS,{
        fetchPolicy: 'network-only',
    });

    const [SaveDailySettings] = useMutation(SAVE_CUSTOMER_DAILY_SETTINGS,{
        onCompleted: (data) => {

            const notification = new Notification();
            notification.setNotification(null,translate.t("SucessfullySaved"),true,false,client);
                        
        },
        onError:(err) => {
            const notification = new Notification();
            notification.setNotification(null,err,false,true,client);
        }

    });

    useEffect(() => {
        if(data)
        {
            var cds = [];
            for(let val of data.customerContext.customerDailySettings)
            {
                cds.push(val.dailySettingsID);
            }

            SrtCustomerDailySettings(cds);
        }
    },[data])

    const DoSwitch = (dailySettingsID,value) => {

        SaveDailySettings({
            variables:{
                dailySettingsID,
                value:(value ? "1" : "0")
            }
        })
    }

    return(
        <div className="whole-container">

            <div className="card main">

                {loading ?
                    <Loading />
                :
                    <>            
                        <div className="card-header d-flex align-items-center">
                            {translate.t('DailySettings')}
                        </div>
                        <div className="card-body">

                            {data.AllDailySettings.map((item) => {

                                var checked = false;
                                if(customerDailySettings.indexOf(item.dailySettingsID) != -1)
                                    checked = true;

                                return(
                                    <div 
                                        className="d-flex align-items-center" 
                                        key={item.dailySettingsID}
                                    >
                                        <label className="input-label mr-2">{translate.t(item.label)}</label>
                                        <div className="ml-auto">
                                            <SwitchInput 
                                                text = ""
                                                value = {checked}
                                                className="smaller"
                                                OnChange = {(value) => DoSwitch(item.dailySettingsID,value)}
                                            />
                                        </div>
                                    </div>
                                )
                            })}
                            
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

export default DailySettings;