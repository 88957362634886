/*
    routování aplikace
*/
import React, { Component, useEffect } from 'react';
import {Switch, Route } from 'react-router-dom';
import LoginForm from '../Modules/Auth/Components/LoginForm';
import Registration from '../Modules/Auth/Components/Registration';
import ConfirmRegistration from '../Modules/Auth/Components/ConfirmRegistration';
import ResetPassword from '../Modules/Auth/Components/ResetPassword';
import NewPassword from '../Modules/Auth/Components/NewPassword';
import Unsubscribe from '../Modules/Event/Components/Unsubscribe';
import Payment from '../Modules/PayU/Components/Payment';
import Layout from '../Layout/Layout.js';
import NoMatch from './NoMatch';
import Questionnaire from '../Modules/Questionnaire/Components/Questions';
import Notification from '../GlobalComponents/Notification';
import { UseTheme } from '../GlobalComponents/Theme';

const Routes = () =>{

    const {SetTheme} = UseTheme();

    useEffect(() => {

      const theme = localStorage.getItem("theme");
      SetTheme(theme);

    },[])
	  	  
    return (
        <>
          <Switch>
          
            <Route exact path='/' component={LoginForm}></Route>
            <Route exact path='/payment/:regCode' component={Payment}></Route>
            <Route exact path='/registration' component={Registration}></Route>
            <Route exact path='/registration/:regCode' component={ConfirmRegistration}></Route>
            <Route exact path='/registration/aff/:affCode' component={Registration}></Route>
            <Route exact path='/reset-password' component={ResetPassword}></Route>
            <Route exact path='/unsubscribe/:code' component={Unsubscribe}></Route>
            <Route exact path='/new-password/:code' component={NewPassword}></Route>
            <Route exact path='/questionnaire' component={Questionnaire}></Route>
            <Route path='/:l' component={Layout}></Route>
            
            <Route component={NoMatch} />

          </Switch>
          <Notification />
        </>
    );
  
}
export default Routes;