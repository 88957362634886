

const YearSeparator = ({year}) => {

    return(
        <div className="year-separator">
            <hr />
            <div className="year">{year}</div>
        </div>
    )
}

export default YearSeparator;