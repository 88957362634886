import Loading from "../../../GlobalComponents/Loading";
import { GET_LAST_NOTIFICATIONS_BY_TIME } from "../Queries/notification";
import { useQuery } from "react-apollo";
import NoItems from '../../../GlobalComponents/NoItems';
import translate from '../../Translations/index';

const NotificationsList = ({SelectNotification,notificationListVariables}) => {

    const {data,error,loading} = useQuery(GET_LAST_NOTIFICATIONS_BY_TIME,{
        fetchPolicy: 'network-only',
        variables:notificationListVariables
    });

    return(
        <div className={"notifications-list" + (data && data.LastCustomerNotificationsByTime.length > 0 ? " no-bottom-padding" : "")}>
            {loading ?
                <Loading />
            :
                <>
                    <div className = "notifications-list-content">
                        
                            
                        {data.LastCustomerNotificationsByTime.length > 0 ?
                            
                            <ul>
                                {data.LastCustomerNotificationsByTime.map((item) => {
                                    return(
                                        <li 
                                            key={item.notificationID}
                                            onClick = {() => SelectNotification(item)}
                                        >
                                            <div className="title">{item.title}</div>
                                            {item.isRead == 0 ?
                                                <div className="circle"></div>
                                            :null}
                                        </li>
                                    )
                                })}
                            </ul>
                        :
                            <div className="form-group">
                                <NoItems text={translate.t("NothingHereYet")} />
                            </div>
                        }
                        
                    </div>
                    {data.LastCustomerNotificationsByTime.length > 0 ?
                        <div className = "notifications-list-footer"></div>
                    :null}
                </>
            }
        </div>
    )
}

export default NotificationsList;