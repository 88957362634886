import infoIcon from '../Public/Images/Icons/info_white.webp';
import { useState } from 'react';

const Badge = ({label,text}) => {

    const [showBadgeInfo, SetShowBadgeInfo] = useState(false);

    const ShowBadgeInfo = (e,value) => {

        e.preventDefault();
        e.stopPropagation();

        SetShowBadgeInfo(value);

    }

    return(
        <>
            <div onClick={(e) => text && ShowBadgeInfo(e,true)} className={"badge badge-danger ml-auto mr-3" + (text ? " cursor-pointer" : "")}>
                {label} 
                {text ? 
                    <img src={infoIcon} />
                :null}
                {showBadgeInfo ?
                    <>
                        <div className="badge-info-text">
                            {text}
                        </div>
                    </>
                :null}
            </div>
            {showBadgeInfo ?
                <div onClick={(e) => ShowBadgeInfo(e,false)} className="badge-info-text-overlay"></div>  
            :null}
        </>
    )
}

export default Badge;