import Calendar from "../../Reservations/Components/Calendar";
import { SERVER_URL,MUJTERAPEUT_SERVER_URL } from "../../../Config";
import translate from '../../Translations/index';

const PrivateMeetingGuideCard = ({customer,headline,text,link,reservationPersonID,noPadding}) => {

    const GetReservationCalendarData = async (month,year,reservationPersonID) => {

        const response = await fetch(MUJTERAPEUT_SERVER_URL + "/get-reservation-data/" + reservationPersonID + "/" + month + "/" + year);
        const data = await response.json();

        var blockedDays = [];
        if(data.resData)
        {
            for(let val of data.resData)
            {
                var d = val.date.split(" ");
                blockedDays.push({date:d[0],customer:val.customer});
            }
        }

        if(data.resBlockedDaysData)
        {
            for(let val of data.resBlockedDaysData)
            {
                blockedDays.push({date:val.date});
            }
        }

        return blockedDays
    }

    return(
        <div className={"service" + (noPadding ? " no-padding" : "")}>
            <div className="icon photo">
                <img src={SERVER_URL + "/Public/Images/Avatar/stredni_" + customer.photo} alt="" />
            </div>
            <div className="card">
                <div className="card-header">
                    {headline}
                </div>
                <div className="card-body">
                    <div className='text-center form-group'>
                        <Calendar
                            showLegend = {true} 
                            showPast   = {false}
                            GetBlockedDays = {(month,year) => GetReservationCalendarData(month,year,reservationPersonID)}
                        />
                    </div>
                    <p>{text}</p>
                    {link ?
                        <div className="text-center">
                            <a target="_blank" href = {link} className="btn btn-primary">{translate.t("GoToReservation")}</a>
                        </div>
                    :null}
                </div>
            </div>
        </div>
    )
}

export default PrivateMeetingGuideCard;