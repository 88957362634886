import React,{useEffect, useState} from 'react';
import { useQuery } from 'react-apollo';
import translate from '../../Translations/index';
import close from '../../../Public/Images/Icons/close.webp';
import Modal from 'react-modal';
import { GET_All_GUIDES } from '../../Customer/Queries/customer';
import Loading from '../../../GlobalComponents/Loading';
import PrivateMeetingGuideCard from './PrivateMeetingGuideCard';

const PopUpGuidePrivateMeetings = () => {

    const [showModal, SetShowModal] = useState(false);

    const {data,loading,error} = useQuery(GET_All_GUIDES,{
        fetchPolicy: 'network-only'
    });

    return(
        <>
            <button onClick={() => SetShowModal(true)} className="btn btn-primary"> 
                <div>{translate.t("PrivateMeetingWithGuide")}</div>
            </button>
            <Modal
                className="Modal__Bootstrap modal-lg modal-dialog"
                isOpen={showModal}
                onRequestClose={() => SetShowModal(false)}
            >
                <div className="modal-content">
                    <div className="modal-header">
                        {translate.t("PrivateMeetingWithGuide")}
                        <img src={close} className="close" onClick={() => SetShowModal(false)} />
                    </div>
                    
                    <div className="modal-body">

                        {loading ? 
                            <Loading />
                        :
                            <div className="row">
                                {data.allGuides.map((item) => {

                                    var reservationPersonID = 1;
                                    var link = "https://mujterapeut.cz/azgarielus";

                                    if(item.customerID == 153)
                                    {
                                        reservationPersonID = 5;
                                        link = "https://mujterapeut.cz/ankara";
                                    }

                                    if(item.customerID == 246)
                                    {
                                        reservationPersonID = 3;
                                        link = "https://mujterapeut.cz/sidris";
                                    }

                                    if(item.customerID == 237)
                                    {
                                        reservationPersonID = 4;
                                        link = "https://mujterapeut.cz/kler";
                                    }

                                    if(item.customerID == 62)
                                    {
                                        reservationPersonID = 6;
                                        link = "https://mujterapeut.cz/akashi";
                                    }

                                    return(

                                        <div key = {item.customerID} className="col-12 col-md-6">
                                                                    
                                            <PrivateMeetingGuideCard
                                                customer = {item}
                                                noPadding = {true}
                                                link = {link}
                                                headline = {item.astralName}
                                                reservationPersonID = {reservationPersonID}
                                            /> 

                                        </div>

                                    )
                                })}
                            </div>
                        }
                        
                    </div>
                </div>
            </Modal>
        </>
    )

}

export default PopUpGuidePrivateMeetings;