import gql from 'graphql-tag';

export const GET_RESERVATION_TIMES_FOR_ROOM = gql`

    query GuideReservationTimesForRoom{
        GuideReservationTimesForRoom{
            guideReservationTimeID
            day
            timeFrom     
            timeTo 
            blockLength
            maxNumberOfResInBlock
            isForMembershipDuration
            isForRoom
        }
    }
`;

export const ADD_EDIT_RESERVATION_TIMES = gql`

    mutation AddEditGuideReservationTime($data:[GuideReservationTimeInput]){
        AddEditGuideReservationTime(data:$data)
    }
`;

export const GET_GUIDE_RESERVATION_DAY_DATA = gql`

    query GuideReservationDayDataForRoom($day:Int,$customerID:ID,$date:String,$guideID:ID){
        GuideReservationDayDataForRoom(day:$day,customerID:$guideID){
            guideReservationTimeID
            customerID
            timeFrom
            timeTo
            blockLength
            maxNumberOfResInBlock
        }
        HasCustomerReservationWithGuide(date:$date,customerID:$customerID,guideID:$guideID)
    }
`;

export const GET_GUIDE_RESERVATIONS_BY_DATE_AND_TYPE = gql`

    query GuideReservationsByDateAndType($date:String,$guideID:ID,$guideReservationTypeID:ID){
        GuideReservationsByDateAndType(date:$date,guideID:$guideID,guideReservationTypeID:$guideReservationTypeID){
            guideReservationID
            resDate
        }
    }
`;

export const GET_GUIDE_RESERVATIONS_BY_DATE = gql`

    query GuideReservationsByDate($date:String,$guideID:ID){
        GuideReservationsByDate(date:$date,guideID:$guideID){
            guideReservationID
            guideReservationTypeID
            customerID
            resDate
            confirmed
            customer{
                name
                surname
                astralName
                roomName
            }
        }
    }
`;

export const ADD_GUIDE_RESERVATION = gql`

    mutation AddGuideReservation($resData:AddGuideReservationInputData){
        AddGuideReservation(resData:$resData){
            guideReservationID
            guideReservationTypeID
            customerID
            resDate
            confirmed
            customer{
                name
                surname
                astralName
                roomName
            }
        }
    }
`;

export const CONFIRM_GUIDE_RESERVATION = gql`

    mutation ConfirmGuideReservation($guideReservationID:ID,$confirmed:Int){
        ConfirmGuideReservation(guideReservationID:$guideReservationID,confirmed:$confirmed)
    }
`;

export const MOVE_GUIDE_RESERVATION = gql`

    mutation MoveGuideReservation($guideReservationID:ID,$guideReservationTypeID:ID,$resDate:String){
        MoveGuideReservation(guideReservationID:$guideReservationID,guideReservationTypeID:$guideReservationTypeID,resDate:$resDate)
    }
`;