/*
    Formulář pro přihlášení se do adminu
*/
import React,{ useEffect, useState } from 'react';
import logo from '../../../Public/Images/logo.svg';
import translate from '../../Translations/index';
import {Mutation} from 'react-apollo';
import {CONFIRM_REGISTER} from '../Queries/registration';
import { withApollo } from 'react-apollo';

var sended = false;

const Registration = (props) => {

    const [error,setError] = useState("");
    const [success,setSuccess] = useState("");

    const regCode = props.match.params.regCode;

    var hostname = window.location.hostname;
    hostname = hostname.split(".");
		   		  
    return (
        <div id="login" className="admin">
            <div className="logo">
                <img alt={translate.t('AstralProjection')} src={logo} />
            </div>

        	<div className="card">
				
				<div className="card-header">{translate.t('AstralProjection')}</div>
				<div className="card-body">
	        	    <form>

                        <div className="form-group">
		        		
                            {error && <div className="alert alert-danger no-margin text-center">{error}</div>}
                            {success && <div className="alert alert-success no-margin text-center" dangerouslySetInnerHTML={{__html:success}}></div>}

                        </div>
		        		      		
                        <Mutation
                            mutation={CONFIRM_REGISTER}
                            onCompleted={async (data) => {

                                if(data.confirmAccount.error != "")setError(data.confirmAccount.error);
                                else{  
                                    setSuccess(translate.t("SuccessConfirmation")); 
                                }
                            }}
                            >
                            {(confirm, { loading, error }) => {

                                // this loading state will probably never show, but it's helpful to
                                // have for testing
                                if (loading) return "";
                                if (error) return setError(error);

                                if(!sended){

                                    sended = true;
                                    setTimeout(() => {

                                        confirm({
                                            variables:{
                                                regCode,
                                                domain:(hostname.length > 1 ? hostname[1] + "." + hostname[2] : hostname[0])
                                            }
                                        });

                                    },250);
                                }
                                                                
                                return <a href="/" className="btn btn-primary btn-block" >{translate.t("BackToLogin")}</a> ;

                            }}
                        </Mutation>

	        		</form>
	        		
	        	</div>
        	</div>
        </div>
    );
  
}

export default withApollo(Registration);
