import React from 'react';
import Loading from '../../../GlobalComponents/Loading';
import translate from '../../Translations/index';
import moment from 'moment';
import TinyMCEEditor from '../../TinyMCE/Components/TinyMceEditor';

const InternalNotes = (props) => {

    return(
        
        
        <div className="card main">
            
            <div className="card-body">

                <TinyMCEEditor onChange={(content,type) => props.SetDescritption(content)} initialValue={props.description}  />
                
            </div>
        </div>
                
                
    );

    

}

export default InternalNotes;