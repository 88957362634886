import React,{useState} from 'react';
import {withApollo,useQuery } from 'react-apollo';
import {GET_EVENTS} from '../Queries/event';
import Error from '../../../GlobalComponents/Error';
import Loading from '../../../GlobalComponents/Loading';
import translate from '../../Translations/index';
import Helper from '../../../Library/helper';
import EventItem from './EventItem';
import YearSeparator from './YearSeparator';
import moment from 'moment';
import NoAccess from '../../../GlobalComponents/NoAccess';

const Events = (props) => {

    var offset = 0;
    var limit = 0;
    var variables = {
        onlyFuture: true,
        offset,
        limit,
        lang: "cz"
    }

    const {data,loading,error,refetch,fetchMore} = useQuery(GET_EVENTS,{
        fetchPolicy: 'network-only',
        variables
    });

    var err = "";
    if(error){

        var helper = new Helper();
        err = helper.getApolloErrorText(error);  

    }

    const GetNextYear = (eventDate,nextEventDate) => {

        var nextYear = null;

        if(nextEventDate)
        {
            const date = eventDate.split("-");
            var nextDate = nextEventDate.split("-");
            if(date[0] != nextDate[0])
                nextYear = nextDate[0];
        }

        return nextYear;
    }

    var weekFree = false;
    var access = false;
    if((data && data.customerContext.membershipTo && moment(data.customerContext.membershipTo).hours(23).minutes(59).seconds(59) >= moment() && !(data.customerContext.lastCompletedCustomerPayment.amount == 0 && moment(data.customerContext.lastCompletedCustomerPayment.dateFrom).add(7,"d").format("YYYY-MM-DD") == moment(data.customerContext.lastCompletedCustomerPayment.dateTo).format("YYYY-MM-DD"))))
        access = true;

    return(
        <>

            {err ?
                <Error text = {err} />
            :
                (loading ?
                    <Loading />
                :
                    <div className={"whole-container " + (!access ? " d-flex flex-column justify-content-center align-items-center flex1" : "")}>
                        
                        {access ?
                            
                            <div className="calendar-events">
                                
                            
                                {data.allEvents && data.allEvents.length > 0 ?

                                    <>
                                    
                                        {data.allEvents.map((item,index) => {

                                            var nextYear = GetNextYear(item.eventDate, (data.allEvents[index + 1] ? data.allEvents[index + 1].eventDate : null));
                                                                                    
                                            return(

                                                <>
                                                    {index == 0 ?
                                                        <YearSeparator year={item.eventDate.split("-")[0]} />
                                                    :null}

                                                    <EventItem key={index} item = {item} />
                                                    
                                                    {nextYear ?
                                                        <YearSeparator year={nextYear} />
                                                    :null}
                                                </>

                                            )
                                        })}

                                        {data.allEventsCount > data.allEvents.length ?

                                            <p className="text-center mt-5">
                                                <button 
                                                    onClick={async  () => await fetchMore({
                                                        variables: {
                                                            offset:data.allEvents.length,
                                                            limit,
                                                            onlyFuture: true,
                                                            lang:translate.locale
                                                        },
                                                        updateQuery: (prev, { fetchMoreResult }) => {
                                                        
                                                        if (!fetchMoreResult) return prev.allEvents;
                                                        return Object.assign({}, prev, {
                                                            allEvents: [...prev.allEvents, ...fetchMoreResult.allEvents]
                                                        });
                                                        }
                                                    })} 
                                                    className="btn btn-primary">
                                                    {translate.t("LoadMore")}
                                                </button>
                                            </p>
                                        
                                        :
                                        null}
                                    
                                    </>

                                :

                                    <div className="card">

                                        <div className="card-body">

                                            <div className="alert alert-warning text-center no-margin">{translate.t("EmptyEvents")}</div>
                                            
                                        </div>

                                    </div>

                                }

                            </div>
                        :
                            <NoAccess customer={data.customerContext.membershipTo && moment(data.customerContext.membershipTo).hours(23).minutes(59).seconds(59) >= moment() ? null : data.customerContext} showFooter = {true} title={translate.t("EventsCalendar")} text={data.customerContext.membershipTo && moment(data.customerContext.membershipTo).hours(23).minutes(59).seconds(59) >= moment() ? translate.t("EventNoAccessInfoFreeWeek") : translate.t("EventNoAccessInfo")} />
                        }

                    </div>
                
                )
            
            }

            

        </>
    )

}

export default withApollo(Events);