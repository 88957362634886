import React,{ useState,useRef, useEffect } from 'react';
import { SERVER_URL } from "../../../Config";
import Lightbox from 'react-image-lightbox';

const ChatMessageImage = ({name,width,height}) => {

    const [showImage,SetShowImage] = useState("");

    return(
        <>
        <div className="img-content">
            <img style={{width:width+"px",height:height+"px"}} onClick={(e) => SetShowImage(e.target.src)} src={SERVER_URL + "/Public/Images/Chat/velka_" + name} />
        </div>
        {showImage &&
            <Lightbox
                mainSrc={showImage}
                onCloseRequest={() => SetShowImage("")}
            />
        }
        </>
    )
}

export default ChatMessageImage;