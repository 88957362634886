import { useEffect, useState } from "react";

const SwitchInput = ({OnChange,text, className,value}) => {

    const [state,SetState] = useState(false);

    const OnSwitchClick = (value) => {
        SetState(value);
        OnChange(value);
    }

    useEffect(() => {
        if(value)
            SetState(value)
    },[value])

    return(
        <div className="switch-input-container">
            {text ?
                <label 
                    onClick = {() => OnSwitchClick(!state)}
                    className="flex-1"
                >{text}</label>
            :null}
            <div 
                onClick = {() => OnSwitchClick(!state)}
                className = {"switch-input " + className + (state ? " on" : "")}
            >
                <div className="thumb"></div>
            </div>
        </div>
    )
}

export default SwitchInput;