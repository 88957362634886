import React,{useState} from 'react';
import {withApollo,useQuery,useMutation } from 'react-apollo';
import Error from '../../../GlobalComponents/Error';
import Loading from '../../../GlobalComponents/Loading';
import {GET_EVENT_CATEGORIES,ADD_EVENT_CATEGORY_TO_NOT_SEND} from '../Queries/emailSmsNotify';
import translate from '../../Translations/index';
import Helper from '../../../Library/helper';
import info from '../../../Public/Images/info.svg';
import Modal from 'react-modal';
import EmailSmsNotifyLib from '../Library/emailSmsNotify';
import Notification from '../../../Library/notification';
import close from '../../../Public/Images/Icons/close.webp';

const EmailSmsNotification = (props) => {

    var emailSmsNotifyLib = new EmailSmsNotifyLib(props);

    let variables = {
        lang:"cz"
    }

    const [eventDetails, showEventDetails] = useState(null);
    const [selectedEventCategories, setSelectedCategories] = useState([]);

    const {data,loading,error,refetch,fetchMore} = useQuery(GET_EVENT_CATEGORIES,{
        fetchPolicy: 'network-only',
        variables,
        onCompleted:(data) => {

            var arr = [];
            for(let val of data.allEventCategoryNotSend){
                arr.push(val.eventCategoryID);
            }

            setSelectedCategories(arr);
        }
    });

    const [addEventCategoryToNotSend,{error:addError,loading:AddLoading}] = useMutation(ADD_EVENT_CATEGORY_TO_NOT_SEND,{
        onCompleted: (data) => {

            var notify = new Notification();
            notify.setNotification(null,translate.t("SuccessfullyChanged"),true,false,props.client);
        }

    });

    var err = "";
    if(error){

        var helper = new Helper();
        err = helper.getApolloErrorText(error);  

    }

    return(
        <div>

            {err ?
                <Error text = {err} />
            :
                (loading ?
                    <Loading />
                :
                    <div className="whole-container">

                        <div className="card main">
										
                            <div className="card-header d-flex align-items-center">
                                {translate.t('Notifications')}
                                <button className="btn btn-primary btn-thiner ml-auto" onClick={() => addEventCategoryToNotSend({
                                    variables:{
                                        eventCategories:selectedEventCategories
                                    }
                                })}>{translate.t('SaveChanges')}</button>
                            </div>
                            <div className="card-body">
                                <p dangerouslySetInnerHTML={{__html:translate.t('DoNotSendEmailsFromThisCatetgories')}}></p>
                                <ul className="no-dots">
                                    {data.allEventCategorys.map((item,index) => {

                                        let checked = false;
                                        for(let val of selectedEventCategories){
                                            if(val == item.eventCategoryID)checked = true;
                                        }

                                        return (
                                            <li className="mb-2" key={index}>
                                                <div className="form-group">
                                                    <div className="form-check form-check-inline">
                                                        <input 
                                                            value={item.eventCategoryID} 
                                                            checked={checked} 
                                                            id={"n-" + index}
                                                            type="checkbox" 
                                                            className="form-check-input" 
                                                            onChange={() => emailSmsNotifyLib.setEventCategory(item.eventCategoryID,selectedEventCategories, setSelectedCategories)} 
                                                        />
                                                        <label htmlFor={"n-" + index} className="form-check-label d-flex align-items-center" >
                                                             <strong>{item.name}</strong> 
                                                             {item.text ?
                                                                <img onClick={(e) => {e.preventDefault();showEventDetails(item);}} className="info-icon ml-2" src={info} title="Klikněte pro detailní popis" />
                                                             :null}
                                                        </label>
                                                    </div>
                                                    
                                                </div>
                                                
                                            </li>
                                        )
                                    })}
                                    
                                </ul>
                                        
                            </div>
                            
                        </div>

                        {eventDetails ?

                            <Modal
                                className="Modal__Bootstrap modal-dialog modal-lg"
                                closeTimeoutMS={150}
                                isOpen={true}
                                onRequestClose={() => showEventDetails(null)}
                            >
                                <div className="modal-content">
                                    <div className="modal-header d-flex align-items-center">
                                        {eventDetails.name}
                                        <img src={close} className="close" onClick={() => showEventDetails(null)} />
                                    </div>
                                    <div className="modal-body event">

                                        <div className="tiny-content" dangerouslySetInnerHTML={{__html:eventDetails.text}}></div>

                                    </div>
                                </div>
                            </Modal>

                        :null}
                        
                    </div>
                
                )
            
            }

        </div>
    )

}

export default withApollo(EmailSmsNotification);