import React,{ Component } from 'react';

const Success = ({thiner,text,className}) => {
	
    return (
    	<div className={"alert alert-success" + (thiner ? " thiner" : "") + (className ? " " + className : "")}>	
    		{text}
    	</div>
    );
  
}


export default Success;
