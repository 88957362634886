import VideoTrack from "./VideoTrack";
import AudioTrack from "./AudioTrack";
import ParticipantLabel from "./ParticipantLabel";
import { useState } from "react";
import { GetFirstLetters,GetName } from "../Library/Functions";

const Participants = ({data,tracksDimensions}) => {

    const {tracksWidth,tracksHeight} = tracksDimensions;
    const [isMuted, SetIsMuted] = useState(data.tracks.length == 0 ? true : false);

    return(
        <div className="tracks" style={{height:tracksHeight + "px", width:tracksWidth + "px"}}>
            <div className="video-wraper">
                            
                {data.tracks.map((track,index) => {

                    if(track.getType() === "video")
                        return(<VideoTrack key={"v"} track={track} />)
                    else
                        return(<AudioTrack key={"a"} track={track} OnMuted = {(muted) => SetIsMuted(muted)} />)

                })} 

                <ParticipantLabel
                    name = {GetName(data.name)}
                    isMuted = {isMuted}
                />

                <div className="no-video">
                    <div className="avatar">{GetFirstLetters(GetName(data.name))}</div>
                </div>
            </div>
        </div>
    );

}

export default Participants;