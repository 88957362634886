import translate from '../../Translations/index';
import {useMutation } from 'react-apollo';
import { useState } from "react"
import {UPDATE_BANK_ACCOUNT} from '../../Customer/Queries/customer';
import {DO_PAID_OFF,ADD_AFFILIATE_URL,GET_AFFILIATE_DATA} from '../Queries/affiliate.js';
import Helper from '../../../Library/helper';
import Notification from '../../../Library/notification';
import getSlug from 'speakingurl';

const INIT_FORM_DATA_ADD_URL = {
    url:"",
    originalUrl:""
}

/**
 * Vyplatit nahromaděné peníze z affiliate
 * @param {*} affDataRefetch 
 * @param {*} showPaidOffNotify 
 * @param {*} client 
 * @returns 
 */

export const UsePaidOff = (affDataRefetch,showPaidOffNotify,client) => {

    const [loading, SetLoading] = useState(false);
    
    const helper = new Helper();
    const notification = new Notification();

    const [PaidOff] = useMutation(DO_PAID_OFF,{
        onCompleted: () => {
            showPaidOffNotify(false);
            affDataRefetch();
            SetLoading(false);
            notification.setNotification(null,translate.t("FinanceIsOnItsWay"),true,false,client);
        },
        onError:(error) => {

            SetLoading(false);

            const err = helper.getApolloErrorText(error);
            notification.setNotification(null,err,false,true,client);
        }
    })

    const [UpdateBankAccount] = useMutation(UPDATE_BANK_ACCOUNT,{
        onCompleted: () => {
            PaidOff();
        },
        onError:(error) => {

            SetLoading(false);

            const err = helper.getApolloErrorText(error);
            notification.setNotification(null,err,false,true,client);
        }
    })

    const DoPaidOff = (formData) => {

        formData.bankNumber = formData.bankNumber.trim();
        formData.swift = formData.swift.trim();
        formData.iban = formData.iban.trim();

        if(formData.bankNumber && formData.bankNumber != "")
        {
            if(
                formData.countryID == 60 || 
                (formData.countryID != 60 && formData.swift && formData.iban)
            )
            {
                SetLoading(true);
                UpdateBankAccount({
                    variables:{
                        bankAccount:formData.bankNumber,
                        swift:formData.swift,
                        iban:formData.iban
                    }
                })
            }
            else
                notification.setNotification(null,translate.t("InvalidSwiftOrIban"),false,true,client);
        }
        else
            notification.setNotification(null,translate.t("InvalidBankAccount"),false,true,client);
        
    }

    return[
        loading,
        DoPaidOff
    ]
}

/**
 * Přidání odkazu pro affiliate
 * @param {*} client apollo client
 * @param {*} SetAffiliateID 
 * @param {*} affiliateID 
 * @returns 
 */

export const UseAddUrl = (client,SetAffiliateID,affiliateID) => {

    const helper       = new Helper();
    const notification = new Notification();

    const [loading, SetLoading] = useState(false);
    const [formData, SetFormData] = useState(INIT_FORM_DATA_ADD_URL);

    const [AddAffiliateUrl] = useMutation(ADD_AFFILIATE_URL,{
        onCompleted: (response) => {

            if(response.addAffiliateUrl.error === "exists")
                notification.setNotification(null,translate.t("AffiliateUrlExists"),false,true,client);
            else if(response.addAffiliateUrl.error === "more")
                notification.setNotification(null,translate.t("Only3AffiliateUrlIsPossible"),false,true,client);
            else
            {
                const affData = client.readQuery({ query: GET_AFFILIATE_DATA});
                const arr = [response.addAffiliateUrl,...affData.allCustomerAffiliateUrl];
                const newAffData = {...affData,allCustomerAffiliateUrl:arr}
            
                client.writeQuery({ 
                    query:GET_AFFILIATE_DATA,
                    data:newAffData 
                });

                SetAffiliateID(0);
                SetFormData(INIT_FORM_DATA_ADD_URL);
    
                notification.setNotification(null,translate.t("AffiliateUrlSuccessfulyAdded"),true,false,client);
            }

            SetLoading(false);

        },
        onError:(error) => {

            SetLoading(false);

            const err = helper.getApolloErrorText(error);
            notification.setNotification(null,err,false,true,client);
        }
    })

    const SetUrl = (text) => {

        const newText = getSlug(text,{lang :'cs',mark:true});
        SetFormData({...formData,url:newText,originalUrl:text});

    }

    const AddUrl = () => {

        if(formData.url !== "")
        {
            SetLoading(true);
            AddAffiliateUrl({
                variables:{
                    url:formData.url,
                    affiliateID
                }
            })
        }
        else
            notification.setNotification(null,translate.t("InvalidAffiliateUrl"),false,true,client);
    }

    return [
        formData,
        loading,
        SetUrl,
        AddUrl
    ]

}
