import { useRef,useEffect, useState } from "react";
import translate from '../../Translations/index';
import ModalNotification from "../../../GlobalComponents/ModalNotification";
import { useMutation } from "react-apollo";
import { DELETE_CHAT_All_MESSAGES } from "../Queries/customer";
import { UseSocket } from "../../Socket/Components/Socket";
import close from '../../../Public/Images/Icons/close.webp';

const ChatOptions = ({me,vs,scrollAfterUpdateMessages,setAllMessages,allMessages,SetShowMessagesOptions}) => {

    const [showNotifyType, SetShowNotifyType] = useState(0);
    const {SendDeleteChatAllMessages} = UseSocket();

    const notifyText = [
        translate.t("DeleteWholeChatOnlyAtMeInfo"),
        translate.t("DeleteWholeChatAtEveryoneInfo"),
        translate.t("DeleteWholeChatAtTheyInfo")
    ]

    const [deleteChatAllMessages] = useMutation(DELETE_CHAT_All_MESSAGES,{
        onCompleted: (data) => {
            
            var arr = [...allMessages];
            for(let i = 0; i < allMessages.length; i++)
            {
                
                if(data.deleteChatAllMessages.type == 3 && allMessages[i].fromCustomerID == vs.customerID)
                {
                    arr[i] = {...arr[i],deletedAtThey:1}                   
                }

                if(data.deleteChatAllMessages.type == 2 && allMessages[i].fromCustomerID == me.customerID)
                {
                    arr[i] = {...arr[i],deleted:1}

                }
                if(data.deleteChatAllMessages.type == 1 && allMessages[i].fromCustomerID == me.customerID)
                {
                    arr[i] = {...arr[i],deletedAtMe:1}   
                }      
                
            }

            if(data.deleteChatAllMessages.type == 2)
                SendDeleteChatAllMessages({
                    deleted:1,
                    fromCustomerID:me.customerID,
                    toCustomerID:vs.customerID
                })

            SetShowMessagesOptions(false);
            
            scrollAfterUpdateMessages.current = false;

            setAllMessages(arr);
           
            
        },
        onError:(error) => {
            console.log(error);
            
        }
    })

    const HandleContextMenu = (e) => {

        e.preventDefault();
        SetShowMessagesOptions(false);
    }

    const DeleteMessage = (act) => {
        if(act)
        {
            deleteChatAllMessages({
                variables:{
                    meID:me.customerID,
                    vsID:vs.customerID,
                    type:showNotifyType
                }
            })
        }

        SetShowNotifyType(0);
    }

    return(
        <>
            <div onClick={() => SetShowMessagesOptions(false)} onContextMenu={(e) => HandleContextMenu(e)} className="chat-options-overlay"></div>
            <div className="chat-options card">
                    
                    <div className="card-header">
                        {translate.t("Options")}
                        <img src={close} className="close" onClick={() => SetShowMessagesOptions(false)} />
                    </div>
                    <div className="card-body">
                        
                            <div className="row">
                                <div className="col-12 col-xl-4">
                                    <div className="form-group">
                                        <button onClick={() => SetShowNotifyType(1)} className="btn btn-primary btn-thiner btn-block">{translate.t("DeleteWholeChatOnlyAtMe")}</button>
                                    </div>
                                </div>
                                <div className="col-12 col-xl-4">
                                    <div className="form-group">
                                        <button onClick={() => SetShowNotifyType(2)} className="btn btn-primary btn-thiner btn-block">{translate.t("DeleteWholeChatAtEveryone")}</button>
                                    </div>
                                </div>
                                <div className="col-12 col-xl-4">
                                    <div>
                                        <button onClick={() => SetShowNotifyType(3)} className="btn btn-primary btn-thiner btn-block">{translate.t("DeleteWholeChatAtThey")}</button>
                                    </div>
                                </div>
                            </div>
                    

                    </div>
                    
                </div>
            {showNotifyType != 0 ?
                <ModalNotification callback={DeleteMessage} text={notifyText[showNotifyType - 1]} yesNo={true} />
            :null}
        </>
    )
}

export default ChatOptions;