import { useRef,useEffect,useState } from "react";

const AudioTrack = ({track,OnMuted}) => {

    var ref = useRef();
    const TrackMuteChange = (muted) => {

        OnMuted(muted);

        if(ref.current)
            ref.current.muted = muted;
        
    }

    useEffect(() => {

        let JitsiMeetJS = window.JitsiMeetJS;

        let audio = ref.current;
        track.attach(audio);

        TrackMuteChange(track.isMuted());
        track.addEventListener(JitsiMeetJS.events.track.TRACK_MUTE_CHANGED,(data) => TrackMuteChange(data.isMuted()));
       
        return  () => {
            track.detach(audio);
            track.removeEventListener(JitsiMeetJS.events.track.TRACK_MUTE_CHANGED,(data) => TrackMuteChange(data.isMuted()));
        }   

    },[])

    return(
        <audio 
            autoPlay={true} 
            ref={ref}
        /> 
    )
}

export default AudioTrack;