import React,{useState} from 'react';
import {useQuery } from 'react-apollo';
import {GET_CUSTOMER_MATERIAL_DATA} from '../Queries/material';
import Loading from '../../../GlobalComponents/Loading';
import SubMaterial from './SubMaterial';
import translate from '../../Translations/index';
import ScrollArea from 'react-scrollbar';
import down from '../../../Public/Images/Icons/arrows_down.webp';
import Helper from '../../../Library/helper';
import Error from '../../../GlobalComponents/Error';

const Material = () => {

    const [openSidebar,setOpenSidebar] = useState(false);

    const [materialContent, setMaterialContent] = useState("");
    const [selectedMaterial, setSelectedMaterial] = useState("");
    const [selectedDegree, setSelectedDegree] = useState(-1);
    const [lessonName, setLessonName] = useState("");

    const {data,loading,error} = useQuery(GET_CUSTOMER_MATERIAL_DATA,{
        fetchPolicy: 'network-only',
        onCompleted:(data) => {
            if(data.customerContext.customerSortedMaterials){
                
                var lastLesson = data.customerContext.customerSortedMaterials[data.customerContext.customerSortedMaterials.length - 1];
                
                if(lastLesson && lastLesson.subMaterial){
                    var lastSubLesson = lastLesson.subMaterial[lastLesson.subMaterial.length - 1];

                    setSelectedMaterial(lastSubLesson.materialID);
                    setMaterialContent(lastSubLesson.description);
                    setLessonName(lastLesson.name + " — " + lastSubLesson.name);
                    setSelectedDegree(lastLesson.materialID);

                }else{
                    lastLesson = data.customerContext.customerSortedMaterials[data.customerContext.customerSortedMaterials.length - 2];

                    if(lastLesson && lastLesson.subMaterial){
                        var lastSubLesson = lastLesson.subMaterial[lastLesson.subMaterial.length - 1];
    
                        setSelectedMaterial(lastSubLesson.materialID);
                        setMaterialContent(lastSubLesson.description);
                        setLessonName(lastLesson.name + " — " + lastSubLesson.name);
                        setSelectedDegree(lastLesson.materialID);

                    }    
                }
            }
        }
    });

    var err = "";
    if(error){

        var helper = new Helper();
        err = helper.getApolloErrorText(error);  
    }

    return(
        
        <div id="material" className={"whole-container"}>	

            {err != "" ?
                <Error text={err} />
            :
                // schválně zakomentováno kdyby se to někdy v budoucnu vracelo
                /*((data && (data.customerContext.membershipTo != null || data.customerContext.customerTypeID == 3)) ?*/
                <>

                    {loading ?
                        <Loading />
                    :
                        (selectedDegree != 0 ?
                            <>
                                {data.customerContext.customerSortedMaterials && data.customerContext.customerSortedMaterials.length > 0 ?
                                    <>
                                        <div className="mobile-lessons" onClick={() => setOpenSidebar(!openSidebar)}>
                                            {lessonName}
                                            <img className="left" src={down} />
                                            <img className="right" src={down} />
                                        </div>
                                        <div>
                                            
                                            <div className="material-content tiny-content">

                                                <div className="card no-margin">
                                                    <div className="card-header">{lessonName}</div>
                                                    <div className="card-body" dangerouslySetInnerHTML={{__html:materialContent}}></div>
                                                </div>
                                                
                                            </div>
                                            <div className={"card side " + (openSidebar ? "open" : "")}>
                                                <ScrollArea
                                                    smoothScrolling={true}
                                                >
                                                    <div className="card-body">
                                                        <SubMaterial selectedDegree={selectedDegree} lessonNames = "" setLessonName={setLessonName} setOpenSidebar={setOpenSidebar} selectedMaterial={selectedMaterial} setSelectedMaterial={setSelectedMaterial} setMaterialContent={setMaterialContent} materials={data.customerContext.customerSortedMaterials} level={0} />
                                                    </div>
                                                </ScrollArea>

                                            </div>

                                        </div>
                                    </>

                                :
                                    <div className="no-materials-content">
                                        <div className="alert alert-warning">{translate.t("NoMaterials")}</div>
                                    </div>

                                }

                            </>
                        :null)
                    
                    }  

                </>
                /*
                :
                    (!loading ?
                        
                        <NoAccess customer={data.customerContext} showFooter = {false} title={translate.t("Materials")} text={translate.t("MaterialInfo")} />

                    :null)

                )*/

            }

        </div>
    )

}

export default Material;