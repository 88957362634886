import React from 'react';
import { withApollo,useQuery } from 'react-apollo';
import {GET_CUSTOMER_MATERIAL_DATA} from '../Queries/customer';
import Loading from '../../../GlobalComponents/Loading';
import SubMaterial from './SubMaterial';

import translate from '../../Translations/index';
import moment from 'moment';

const Materials = (props) => {

    const {data,loading,error,} = useQuery(GET_CUSTOMER_MATERIAL_DATA,{
        variables:{
            lang:"cz",
            customerID:props.customerID
        },
        fetchPolicy: 'network-only',
    });

    return(
        
        
        <div className="card main">
            
            <div className="card-header d-flex align-items-center">
                Seznam materiálů
                <div className="ml-auto">{translate.t("NewLevelDate") + ": "} <strong>{(props.customer.materialChangeDate != "0000-00-00 00:00:00" ? moment(props.customer.materialChangeDate).format("DD.MM.YYYY") : translate.t("NoOpened"))}</strong></div>
            </div>
            <div className="card-body">

                {loading ?
                    <Loading />
                :
                    <div>

                        {data.allGuideMaterialsToOpen && data.allGuideMaterialsToOpen.length > 0 ?

                            <SubMaterial selectedMaterials = {props.selectedMaterials} materials={data.allGuideMaterialsToOpen} allMaterials = {data.allGuideMaterialsToOpen} level={0} setSelectedMaterials={props.setSelectedMaterials} />

                        :
                            <div className="alert alert-warning text-center">Nebyly nalezeny žádné materiály</div>
                        }

                    </div>
                }
                
            </div>
        </div>
                
                
    );

    

}

export default withApollo(Materials);