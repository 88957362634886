import { useRef,useEffect,useState } from "react";

const VideoTrack = ({track}) => {

    var ref = useRef();
    const [isMuted, SetIsMuted] = useState(true);

    const TrackMuteChange = (data) => {
        SetIsMuted(data.isMuted())
    }

    const ErrorHandler = (err) => {
        console.warn(err);
    }

    useEffect(() => {

        let JitsiMeetJS = window.JitsiMeetJS;

        let video = ref.current;
        video.addEventListener('error', ErrorHandler);
        track.attach(video);
        
        TrackMuteChange(track);
        track.addEventListener(JitsiMeetJS.events.track.TRACK_MUTE_CHANGED,TrackMuteChange);
        
        return  () => {
            track.detach(video);
            video.removeEventListener('error', ErrorHandler);
            track.removeEventListener(JitsiMeetJS.events.track.TRACK_MUTE_CHANGED,TrackMuteChange);
        }
        
    },[])

    return(
        
        <video 
            className={(isMuted ? "hidden" : "")} 
            autoPlay={true} 
            ref={ref}
            playsInline = {true}
        />
        
    )
}

export default VideoTrack;