import React,{ Component } from 'react';
import logo from '../../../Public/Images/logo.svg';
import emailIcon from '../../../Public/Images/Icons/email_24.webp';
import emailIconA from '../../../Public/Images/AnimatedIcons/email.webp';
import Helper from '../../../Library/helper';
import { withApollo } from 'react-apollo';
import {SEND_FORGOT_PASS_EMAIL} from '../Queries/changePassword';
import translate from '../../Translations/index';

const INITIAL_STATE = {	 
    email: '',
	error: null,
	message:'',
	emailIcon:emailIcon
};

class ResetPassword extends Component {
		
  constructor(props){
	    
	  super(props);
	  this.onSubmit = this.onSubmit.bind(this);
	  this.goBack   = this.goBack.bind(this);
	  this.state    = { ...INITIAL_STATE };
	  
  }	
      
  goBack(){ 
	  this.props.history.push("/");
  }
  
  onSubmit(e){
	  
	  e.preventDefault();
	  
	  var {email} = this.state;
	  const {history} = this.props;

	  email = email.trim();
	  	  
	  if(email !== ''){

		var helper = new Helper();
		 
		if(helper.validateEmail(email)){

			this.props.client.mutate({
				mutation: SEND_FORGOT_PASS_EMAIL,
				variables:{
					email,
					lang: translate.locale
				},
				update:(cache, {data}) => {
					if(data.sendForgotPassEmail == ""){
						this.setState({'message':translate.t('SentEmailForPwdGeneration')});
						setTimeout(() => history.push("/"),4000);
					}else{
						this.setState({'error':data.sendForgotPassEmail});
						setTimeout(() => this.setState({'error':''}),4000);
					}
				}
			})
		  
		}else{
			this.setState({'error':translate.t('EmailInWrongFormat')});
			setTimeout(() => this.setState({'error':''}),4000);
		}
		  
	  }else{
		  this.setState({'error':translate.t('NotFilledEmail')});
		  setTimeout(() => this.setState({'error':''}),4000);
	  }
	  
	  

  }

  render() {

	const {email,error,message} = this.state;  
	  
    return (
        <div id="login">
        
	        <div className="logo">
                <img alt={translate.t('AstralProjection')} src={logo} />
            </div>
        	<div className="card">
				
				<div className="card-header">{translate.t('AstralProjection')}</div>
				<div className="card-body">

	        	    <form onSubmit={this.onSubmit}>
		        		
		        		<div className="form-group fs-14">
		        			
						{translate.t('EnterEmailForPwdGeneration')}
	
		        		</div>
		        		<div className="form-group">
		        				        			
		        			<div className="input-group">
								<div className="input-group-prepend">
		        			    	<span className="input-group-text" ><img alt="" src={this.state.emailIcon} /></span>
		        			    </div>
		        			    <input
								  onMouseEnter={() => this.setState({emailIcon:emailIconA})}
								  onMouseOut={() => this.setState({emailIcon:emailIcon})}
		        			      onChange={event => this.setState({'email':event.target.value})}
		        			      value={email}
		        			      type="text" placeholder={translate.t('Email')} className="form-control" aria-label={translate.t('Email')} />
		        			</div>
		        			
		        		</div>
		        		<div className="row">
		        			<div className="col-6">
		        				<button type="submit" className="btn btn-primary btn-blue btn-block">{translate.t('Generate')}</button>
		        			</div>
		        			<div className="col-6">
		        				<button onClick={this.goBack} className="btn btn-danger btn-block">{translate.t('Back')}</button>
		        			</div>
		        		</div>
		        		
		    			{ error && <div className="alert alert-danger mt-4 mb-0 text-center">{error}</div> }
		    			{ message && <div className="alert alert-success mt-4 mb-0  text-center">{message}</div> }
		    			
	        		</form>
        		</div>
        	</div>
        
        </div>
    );
  }
}



export default withApollo(ResetPassword);
