import translate from '../../Translations/index';
import React,{useState,useRef, useEffect} from 'react';
import {useQuery,useMutation} from 'react-apollo';
import {GET_COMPLETE_QUESTIONNAIRE_STEPS,SAVE_QUESTIONNAIRE_STEPS,SET_QUESTIONNAIRE_STEPS_COMPLETED} from '../Queries/questionnaire';
import Loading from '../../../GlobalComponents/Loading';
import Helper from '../../../Library/helper';
import QuestionnaireText from './QuestionnaireText';
import QuestionnaireCheckbox from './QuestionnaireCheckbox';
import QuestionnaireRadio from './QuestionnaireRadio';

/**
 * params jsou ID uživatele a funkce finished když je dotazník dokončen, lastQuestionnaireStepID je poslední ID nedokončeného kroku
 * @param {customerID, finished, lastQuestionnaireStepID} props 
 * @returns 
 */

const Questionnaire = (props) => {

    const shadowHeight = 5;

    const introRef = useRef(null);
    const stepProgressReg = useRef(null);
    const stepsRef = useRef([]);
    const [formDataSet, setFormDataSet] = useState(null);
    const [errorText, setErrorText] = useState(0);
    const [step, setStep] = useState(0);
    const [containerStyle, setContainerStyle] = useState(null);
    const [contentStyle, setContentStyle] = useState(null);
    const [stepContentStyle, setStepContentStyle] = useState(null);
    const [stepContainertStyle, setStepContainerStyle] = useState(null);
    const [formData,setFormData] = useState([]);

    const {data,loading,error} = useQuery(GET_COMPLETE_QUESTIONNAIRE_STEPS,{
        variables:{
            lang: translate.locale,
            customerID: props.customerID,
            questionnaireID: props.questionnaireID
        }
    })

    const [saveStepToDb,{loading:loadingAdd,error:errorAdd}] = useMutation(SAVE_QUESTIONNAIRE_STEPS);
    const [setQuestionnaireStepsCompleted,{loading:loadingSet,error:errorSet}] = useMutation(SET_QUESTIONNAIRE_STEPS_COMPLETED);

    const keyPress = (key) => {

        if(key.keyCode == 9)key.preventDefault();

        if(key.keyCode == 13){
            nextStep(step + 1);
        }

    }

    useEffect(() => {

        document.addEventListener("keydown", keyPress);
        return(() => document.removeEventListener("keydown", keyPress));

    },[step]);

    useEffect(() => {

        if(introRef.current)setContainerStyle({height: introRef.current.offsetHeight + "px"});
        
    },[loading]);

    useEffect(() => {

        if(data && data.getCompleteQuestionnaireSteps.length > 0){

            var arr = [];
            var nextI = 0;
            if(data.customerQuestionnaireSteps.length > 0){

                for(let j = 0; j < data.customerQuestionnaireSteps.length; j++){

                    var obj = {
                        questionnaireStepID:data.customerQuestionnaireSteps[j].questionnaireStepID,
                        questionTypeID:data.customerQuestionnaireSteps[j].questionnaireStep.questionTypeID,
                        text : data.customerQuestionnaireSteps[j].text,
                        values : data.customerQuestionnaireSteps[j].checkboxQuestionnaireStepItemIDs.split(","),
                        value : data.customerQuestionnaireSteps[j].radioQuestionnaireStepItemID
                    }
    
                    arr.push(obj);
                }

                nextI = data.customerQuestionnaireSteps.length;
            }

            for(let i = nextI; i < data.getCompleteQuestionnaireSteps.length; i++){

                var obj = {
                    questionnaireStepID:data.getCompleteQuestionnaireSteps[i].questionnaireStepID,
                    questionTypeID:data.getCompleteQuestionnaireSteps[i].questionTypeID,
                    text : "",
                    values : [],
                    value : 0
                }

                arr.push(obj);
            }

            setFormData(arr);
            setFormDataSet(true);

        }

    },[data]);

    useEffect(() => {

        if(formDataSet){
            if(data.customerQuestionnaireSteps.length > 0){
                setContentStyle({transform: "translateY(" + -introRef.current.offsetHeight + "px)"});
                nextStep(data.customerQuestionnaireSteps.length + 1);
            }
        }

    },[formDataSet])

    const nextStep = (nextStep) => {

        if(nextStep > data.getCompleteQuestionnaireSteps.length + 1){

            if(props.finished)props.finished();

        }else{

            if(nextStep == 1){
                setStep(nextStep);
                setContentStyle({transform: "translateY(" + -introRef.current.offsetHeight + "px)"});
                setContainerStyle({height: (stepsRef.current[0].offsetHeight + stepProgressReg.current.offsetHeight + 10 + shadowHeight) + "px"});
                setStepContainerStyle({height: stepsRef.current[0].offsetHeight + shadowHeight + "px"});
                
            }else{
                if((formData[nextStep - 2].text != "" && formData[nextStep - 2].questionTypeID == 1) || formData[nextStep - 2].questionTypeID != 1){
                
                    if(formData[nextStep - 2].questionTypeID != 2 || (formData[nextStep - 2].values.length != 0 && formData[nextStep - 2].questionTypeID == 2)){
                
                        if(formData[nextStep - 2].questionTypeID != 3 || (formData[nextStep - 2].value != 0 && formData[nextStep - 2].questionTypeID == 3)){
                
                            setStep(nextStep);
                            setStepContentStyle({transform: "translateY(" + -stepsRef.current[nextStep - 1].offsetTop + "px)"});
                            setContainerStyle({height: (stepsRef.current[nextStep - 1].offsetHeight + stepProgressReg.current.offsetHeight + 10 + shadowHeight) + "px"});
                            setStepContainerStyle({height: stepsRef.current[nextStep - 1].offsetHeight + shadowHeight + "px"});
                            
                            saveStepToDb({
                                variables:{
                                    customerID:props.customerID,
                                    questionnaireID: props.questionnaireID,
                                    data:formData[nextStep - 2]
                                }
                            })

                            if(nextStep == data.getCompleteQuestionnaireSteps.length + 1){
                                setQuestionnaireStepsCompleted({
                                    variables:{
                                        customerID:props.customerID
                                    }
                                })
                            }
                            
                        }else{
                            setErrorText(translate.t("QuestionIsNotFilled"));
                            setTimeout(()=>{
                                setErrorText("");
                            },3000)
                        }
                    
                    }else{
                        setErrorText(translate.t("QuestionIsNotFilled"));
                        setTimeout(()=>{
                            setErrorText("");
                        },3000)
                    }
                
                }else{
                    setErrorText(translate.t("QuestionIsNotFilled"));
                    setTimeout(()=>{
                        setErrorText("");
                    },3000)
                }
            }
        }
    }

    const setText = (value,index) => {

        var arr = [...formData];
        arr[index].text = value;
        setFormData(arr);

    }

    const setCheckbox = (value,index) => {

        var arr = [...formData];
        arr[index].values = value;
        setFormData(arr);

    }

    const setRadio = (value,index) => {

        var arr = [...formData];
        arr[index].value = value;
        setFormData(arr);

    }

    var err = "";
    if(error || errorAdd || errorSet){

        var helper = new Helper();
        err = helper.getApolloErrorText(error || errorAdd || errorSet);

    }

    return(
        <div id="questionnaire">

            {loading ?

                <Loading />
            
            :
                (err ?

                    <div className="alert alert-danger mr-5 ml-5 mb-0">{err}</div>
                    
                :
                    <>

                        <div className="questionnaire-container" style={containerStyle}>

                            <div className="questionnaire-content" style={(step > 0 ? contentStyle : null)}>

                                <div ref={introRef} className="introduction">
                                    <div dangerouslySetInnerHTML={{__html: data.questionnaire.welcomeMessage}}></div>
                                </div>
                            
                                <div className="steps">

                                    <div className="step-progress" ref={stepProgressReg}>
                                        <div className="step-number">{(step > data.getCompleteQuestionnaireSteps.length ? data.getCompleteQuestionnaireSteps.length : step)} / {data.getCompleteQuestionnaireSteps.length}</div>
                                        <div className="step-progressbar">

                                            {data.getCompleteQuestionnaireSteps.map((item,index) => {

                                                return(
                                                    <div key={index} className={"line"}>
                                                        <div className={"overline" + (step > index + 1 ? " done" : (step == index + 1 ? " here" : ""))}></div>
                                                    </div>
                                                )

                                            })}

                                        </div>
                                    </div>

                                    <div className="steps-container" style={stepContainertStyle}>

                                        <div className="steps-content" style={stepContentStyle}>

                                            {data.getCompleteQuestionnaireSteps.map((item,index) => {

                                                if(item.questionTypeID == 1) return(<QuestionnaireText callBack={(value) => setText(value,index)} question={item.question} key={index} ref={el => stepsRef.current[index] = el} />);
                                                if(item.questionTypeID == 2) return(<QuestionnaireCheckbox callBack={(value) => setCheckbox(value,index)} question={item.question} questionnaireStepID = {item.questionnaireStepID} items={item.items} key={index} ref={el => stepsRef.current[index] = el} />);
                                                if(item.questionTypeID == 3) return(<QuestionnaireRadio callBack={(value) => setRadio(value,index)} question={item.question} questionnaireStepID = {item.questionnaireStepID} items={item.items} key={index} ref={el => stepsRef.current[index] = el} />);

                                            })}
                                            <div className="step" ref={el => stepsRef.current[data.getCompleteQuestionnaireSteps.length] = el}>
                                                <div className="alert alert-success mb-0 mt-0 text-center" dangerouslySetInnerHTML={{__html:
                                                    (props.successMessage 
                                                        ?
                                                            props.successMessage
                                                        :
                                                            translate.t("ThatsAllThankYou")
                                                    )}}>    
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                        <p className="text-center no-bottom-margin mt-30">
                            <button className="btn btn-primary btn-large min-w-250" onClick={() => nextStep(step + 1)}>{(step == 0 ? translate.t("GoForIt") : (step > data.getCompleteQuestionnaireSteps.length ? translate.t("GoToPortal") : translate.t("Continue")))}</button>
                        </p>

                        {errorText ?
                            <div className="alert alert-danger text-center mt-30 mb-0">{errorText}</div>
                        :null}

                    </>
                )
            }

        </div>
    )

}

export default Questionnaire;