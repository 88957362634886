import React, { useState } from 'react';
import Modal from 'react-modal';
import translate from '../../../Modules/Translations/index';
import close from '../../../Public/Images/Icons/close.webp';
import Helper from '../../../Library/helper';
import { ADD_GUIDE_RESERVATION,GET_GUIDE_RESERVATIONS_BY_DATE_AND_TYPE } from '../../Reservations/Queries/guideReservation';
import { useMutation,useQuery } from 'react-apollo';
import Loading from '../../../GlobalComponents/Loading';
import moment from 'moment';
import Notification from '../../../Library/notification';
import { UseSocket } from '../../Socket/Components/Socket';

Modal.setAppElement('#root');

const ReservationTimes = ({OnClose,OnComplete,reservationTimesData,customerID,guideID,roomNumber,client}) => {

    const helper = new Helper();
    const {SendGuideReservation} = UseSocket();

    const [selectedPanel, SetSelectedPanel] = useState(0);
    const [selectedBlock, SetSelectedBlock] = useState(null);

    const {data,loading,refetch} = useQuery(GET_GUIDE_RESERVATIONS_BY_DATE_AND_TYPE,{
        fetchPolicy: 'network-only',
        variables:{
            date:moment().format("YYYY-MM-DD"),
            guideID:guideID,
            guideReservationTypeID:1
        }
    });

    const [AddGuideReservation,{loading:addLoading}] = useMutation(ADD_GUIDE_RESERVATION,{
        onCompleted:(resData) => {

            var response = resData.AddGuideReservation;
                
            SendGuideReservation({
                roomNumber:roomNumber,
                reservations:{
                    guideReservationID: response.guideReservationID,
                    guideReservationTypeID: response.guideReservationTypeID,
                    customerID: response.customerID,
                    confirmed:0,
                    resDate: response.resDate,
                    customer: response.customer
                }
            })

            OnComplete();
        },
        onError:(err) => {
            if(helper.getApolloErrorText(err) == "ERR_THIS_DAY_IS_FULL")
            {
                let notify = new Notification();
                notify.setNotification(null,translate.t("SomeoneMakeResBeforeYou"),false,true,client);

                SetSelectedBlock(null);
                refetch();
            }
        }
    });

    var resBlocks = [];

    if(data)
    {
        for(let val of reservationTimesData.GuideReservationDayDataForRoom)
        {
            var timeF = helper.GetMinutesFromTime(val.timeFrom);
            var timeT = helper.GetMinutesFromTime(val.timeTo);

            for(let i = timeF; i < timeT; i += val.blockLength)
            {
                var count = 0;
                for(let r of data.GuideReservationsByDateAndType)
                {
                    var d = r.resDate.split(" ");
                    var m = helper.GetMinutesFromTime(d[1]);

                    if(m == i)
                        count++
                }

                resBlocks.push({
                    guideReservationTimeID: val.guideReservationTimeID,
                    timeFrom: i,
                    blocked:(count >= val.maxNumberOfResInBlock ? true : false),
                    timeFromStr: helper.GetTimeFromMinutes(i),
                    timeToStr: helper.GetTimeFromMinutes(i + val.blockLength)
                })
            }
        }
    }

    const SelectPanel = (number) => {
        SetSelectedPanel(number);
        SetSelectedBlock(null);
    }

    const Save = () => {

        if(selectedPanel != 0)
        {
            if((selectedPanel == 1 && selectedBlock) || selectedPanel != 1)
            {
                var guideReservationTimeID = 0;
                
                var resDate = "";

                if(selectedBlock)
                {
                    guideReservationTimeID = selectedBlock.guideReservationTimeID;
                    resDate = moment().format("YYYY-MM-DD") + " " + selectedBlock.timeFromStr;
                }
                else
                {
                    guideReservationTimeID = reservationTimesData.GuideReservationDayDataForRoom[0].guideReservationTimeID;
                    resDate = moment().format("YYYY-MM-DD 00:00:00");
                }
                
                AddGuideReservation({
                    variables:{
                        resData:{
                            guideReservationTimeID: guideReservationTimeID,
                            guideReservationTypeID: selectedPanel,
                            guideID: guideID,
                            customerID: customerID,
                            resDate:resDate,
                        }
                    }
                })
            }
            else
            {
                let notify = new Notification();
                notify.setNotification(null,translate.t("YouDoNotChooseTimeRes"),false,true,client);
            }
        }
        else
        {
            let notify = new Notification();
            notify.setNotification(null,translate.t("ChooseWhenYouWantToTalk"),false,true,client);
        }
    } 

    return(
        <Modal
	        className="Modal__Bootstrap modal-dialog"
	        closeTimeoutMS={150}
	        isOpen={true}
	        onRequestClose={() => OnClose()}
	    >
	        <div className="modal-content">
                <div className="modal-header">
                    {translate.t("ChooseWhenYouWilBeSpeaking")}
                    <img src={close} className="close" onClick={() => OnClose()} />
                </div>
                <div className="modal-body">

                    {loading || addLoading ? 
                        <Loading />
                    :
                        <>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-4">
                                        <div 
                                            onClick = {() => SelectPanel(1)}
                                            className = {"clickable-panel " + (selectedPanel == 1 ? "selected" : "")}
                                        >
                                            {translate.t("SpecificTime")}
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div 
                                            onClick = {() => SelectPanel(2)}
                                            className = {"clickable-panel " + (selectedPanel == 2 ? "selected" : "")}
                                        >
                                            {translate.t("Anytime")}
                                        </div>
                                    </div>
                                    
                                    <div className="col-4">
                                        <div 
                                            onClick = {() => SelectPanel(3)}
                                            className = {"clickable-panel "  + (selectedPanel == 3 ? "selected" : "")}
                                        >
                                            {translate.t("JustListening")}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {selectedPanel == 1 ?

                                <div className="res-blocks form-group">
                                    {resBlocks.map((item,index) => {
                                        return(
                                            <div 
                                                key = {index}
                                                onClick = {() => !item.blocked && SetSelectedBlock(item)}
                                                className = {"block " + (item.blocked ? "blocked" : "") + (selectedBlock && item.timeFrom == selectedBlock.timeFrom ? " selected" : "")}
                                            >
                                                <span>{item.timeFromStr}</span>
                                                {resBlocks.length - 1 == index ?
                                                    <span className="bottom">{item.timeToStr}</span>
                                                :null}
                                            </div>
                                        )
                                    })}
                                </div>
                            
                            :null}

                            <div 
                                onClick = {() => SelectPanel(4)}
                                className = {"clickable-panel "  + (selectedPanel == 4 ? "selected" : "")}
                            >
                                {translate.t("HaveIndividualGuidance")}
                            </div>
                        </>
                    }
                        
                </div>
			  
				<div className="modal-footer"> 
					
                    <button type="button" className="btn btn-primary flex-fill" onClick={() => Save()}>{translate.t("SaveAndEnrterRoom")}</button>
                    <button type="button" className="btn btn-danger" onClick={() => OnClose()}>{translate.t("Close")}</button>
						
				</div>
			 
	        </div>
	    </Modal>
    )
}

export default ReservationTimes;